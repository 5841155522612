import { config } from 'src/config/config'
import { appApi } from './AppApi'
const baseUrl = config.baseUrl


export const userLoginApi = async (data = {}) => { return await appApi(`${baseUrl}user/loginSignup`, "POST", {}, data) }

export const validateUserApi = async (data = {}) => { return await appApi(`${baseUrl}user/validate`, "POST", {}, data) }

export const sendEnquiryEmailApi = async (data = {}) => { return await appApi(`${baseUrl}user/enquiry`, "POST", {}, data) }

export const blogUpdateCreateApi = async (data = {}) => { return await appApi(`${baseUrl}blog/createUpdate`, "POST", {}, data) }

export const sendEnquiryEmailOtpApi = async (data = {}) => { return await appApi(`${baseUrl}user/enquiry-otp`, "POST", {}, data) }

export const getBlogDataApi = async (data = {}) => { return await appApi(`${baseUrl}blog/getBlogs`, "POST", header(), JSON.stringify(data)) }

export const getLandingBlogDataApi = async (data = {}) => { return await appApi(`${baseUrl}landing/getBlogs`, "POST", header(), JSON.stringify(data)) }

export const getUserEnquiryListApi = async (data = {}) => { return await appApi(`${baseUrl}user/enquiryList`, "POST", header(), JSON.stringify(data)) }

export const addBlogViewCountApi = async (data = {}) => { return await appApi(`${baseUrl}landing/addViewCount`, "POST", header(), JSON.stringify(data)) }

export const header = (type = 'json') => {
    const header = {
        plain: "text/plain", // Plain text without any specific formatting.
        html: "text/html", // Hypertext Markup Language (HTML) documents, used for web pages.
        css: "text/css", // Cascading Style Sheets (CSS) files, used to style HTML documents.
        json: "application/json", // JavaScript Object Notation (JSON) data.
        xml: "application/xml", // Extensible Markup Language (XML) documents.
        pdf: "application/pdf", // Portable Document Format (PDF) files.
        jpeg: "image/jpeg", // JPEG images.
        png: "image/png", // Portable Network Graphics (PNG) images.
        gif: "image/gif", // Graphics Interchange Format (GIF) images.
        mpeg: "audio/mpeg", // MPEG Audio Layer III (MP3) audio files.
        mp4: "video/mp4", // MPEG-4 multimedia files.
        octetStream: "application/octet-stream", // Binary data, not belonging to any specific media type.
        formData: "multipart/form-data", // Used in forms to submit binary data along with other form data.
        formurlencoded: "application/x-www-form-urlencoded", // Form data encoded as key-value pairs.
        csv: "text/csv", // Comma-separated values (CSV) files, often used for spreadsheets.
        zip: "application/zip", // ZIP archive files.
    }
    return { "Content-Type": header[type] };
}