export function validator(data) {
    const validateReturn = [];
    const validate = data?.validate ?? ['required'];

    if (validate.includes('required') && required(data.value)?.notValid) { validateReturn.push(required(data.value)) }
    if (validate.includes('fixedLength') && fixedLength(data.value, data.length)?.notValid) { validateReturn.push(fixedLength(data.value, data.length)) }
    if (validate.includes('maxLength') && maxLength(data.value, data.maxLength)?.notValid) { validateReturn.push(maxLength(data.value, data.maxLength)) }
    if (validate.includes('minLength') && minLength(data.value, data.minLength)?.notValid) { validateReturn.push(minLength(data.value, data.minLength)) }
    if (validate.includes('email') && email(data.value)?.notValid) { validateReturn.push(email(data.value)) }
    return validateReturn

}


export function required(e) {
    if ((Array.isArray(e) && e.length === 0) || e === '' || e === null || e === undefined) {
        return response('This field is required', false)
    } else {
        return response('', true)
    }
}

export function fixedLength(e, length) {
    if (e?.length === length) {
        return response('', true)
    } else {
        return response(`This field must contains ${length} letter`, false)
    }

}
export function maxLength(e, length) {
    if (e?.length >= length) {
        return response('', true)
    } else {
        return response(`This field should be less than ${length}`, false)
    }

}

export function minLength(e, length) {
    if (e?.length <= length) {
        return response('', true);
    } else {
        return response(`This field should be greater than ${length}`, false)
    }

}

export function email(e) {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (pattern.test(e)) {
        return response('', true);
    } else {
        return response(`Email is Invalid`, false)
    }

}

export function response(msg, notValid) {
    return {
        msg,
        notValid: !notValid
    }
}