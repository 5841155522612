import PropTypes from 'prop-types';
import { TextField, InputAdornment, Stack, Button, Box, IconButton, Chip, Typography, CardMedia } from '@mui/material';
import { EmailOutlined, CloudUpload as CloudUploadIcon, Clear as ClearIcon } from '@mui/icons-material';
import { useState, useEffect } from 'react';

import { toastError, toastSuccess } from 'src/Helpers/toast'

FileUpload.propTypes = {
    title: PropTypes.string,
    maxHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    others: PropTypes.object,
    updateFileValue: PropTypes.func
};
const BUTTON_STYLE = {
    fontWeight: 700,
    paddingLeft: 2,
    paddingRight: 2
}


export default function FileUpload({ title, maxWidth = '100%', maxHeight = '200', others = {}, updateFileValue, inputValue = '' }) {
    const [filePreview, setFilePreview] = useState(null);
    const [showButton, setshowButton] = useState(false);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (event.target.files[0].type.startsWith('image/')) {
                updateFileValue(event.target.files[0])

                const reader = new FileReader();
                reader.onloadend = () => {
                    setFilePreview(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                updateFileValue('')
            }
        }
    };
    useEffect(() => {
        setFilePreview(inputValue)
    }, [inputValue])

    const handleFileClear = () => {
        setFilePreview("")
        updateFileValue('')
    }
    return (
        <Box
            sx={{
                ...others,
                padding: 1,
                border: '1px solid blue',
                width: maxWidth,
                height: `${parseInt(maxHeight) + 25}px`,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                backgroundColor: "#343a40",
                '&:hover': {
                    border: '1px solid #343a40'
                },
                border: '1px solid #343a40',
                borderRadius: '10px'
            }}
            onMouseEnter={() => {
                setshowButton(true)
            }}
            onMouseLeave={() => {
                setshowButton(false)
            }}

        >
            {showButton && filePreview !== '' && filePreview !== undefined && filePreview !== null ?
                <Stack direction='row' sx={{ position: 'absolute' }} spacing={4}>
                    <Button
                        variant="outlined"
                        component="label"
                        sx={{
                            ...BUTTON_STYLE,
                            borderRadius: 20,
                            backgroundColor: '#222222',
                            '&:hover': {
                                backgroundColor: '#222222',
                            }
                        }}
                        startIcon={<CloudUploadIcon />}

                    >
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                        {title}
                    </Button>
                    <Button
                        variant="outlined"
                        component="label"
                        onClick={handleFileClear}
                        sx={{
                            ...BUTTON_STYLE,
                            borderRadius: 20,
                            backgroundColor: '#222222',
                            '&:hover': {
                                backgroundColor: '#222222',
                            }
                        }}
                        color="error"
                    >
                        <ClearIcon />
                        Clear
                    </Button>

                </Stack> : <></>}
            {
                filePreview ? (
                    <CardMedia
                        component="img"
                        image={filePreview}
                        alt="File Preview"
                        height={`${maxHeight}px`}
                        width={maxWidth}

                    />
                ) : (
                    <Button
                        variant="container"
                        component="label"
                        sx={{
                            ...BUTTON_STYLE,
                            backgroundColor: "#343a40",
                            border: '#343a40',
                            height: '100%', color: 'white', '&:hover': {
                                backgroundColor: "#343a40"
                            }
                        }}
                        startIcon={<CloudUploadIcon />}

                    >
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                        {title}
                    </Button>
                )
            }
        </Box >
    );
}