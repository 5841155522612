const configuration = {
    "dev": {
        baseUrl: 'http://localhost:3010/api/v1/',
        serverImage: 'http://localhost:3010/',
        googleAnalyticId: 'G-KF0E118D8H',
        chatApiUrl: 'http://49.207.58.143:3002/',
        environment: "dev",
        GEMINI_AI_KEY: "AIzaSyAKPUcdC7JPpjuohOfP4r57s2jH-C8R-rQ"
    },
    "qa": {
        serverImage: 'https://stage.inolabs.ai/api/v1/',
        baseUrl: 'https://stage.inolabs.ai/api/v1/',
        googleAnalyticId: 'G-KF0E118D8H',
        chatApiUrl: 'http://49.207.58.143:3002/',
        environment: "qa",
        GEMINI_AI_KEY:"AIzaSyAcPwVyU4bOOcBHgerdheCEr6RYuC9ThKY"
    },
    "prod": {
        serverImage: 'https://inolabs.ai/api/v1/',
        baseUrl: 'https://inolabs.ai/api/v1/',
        googleAnalyticId: 'G-36N2SJNV7Y',
        chatApiUrl: 'http://49.207.58.143:3002/',
        environment: "prod",
        GEMINI_AI_KEY:"AIzaSyAcPwVyU4bOOcBHgerdheCEr6RYuC9ThKY"
    }
}
const environment = process.env.REACT_APP_ENVIRONMENT || "qa"
export const config = configuration[environment]