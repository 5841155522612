
import Router from './routes';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA4 from 'react-ga4';
import { useEffect } from 'react';
import {config} from './config/config'

function App() {
  useEffect(() => {
    ReactGA4.initialize(config.googleAnalyticId);
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
