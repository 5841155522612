import { Navigate } from "react-router-dom"
import { checkEmpty } from 'src/Helpers/helpers'
import { config } from 'src/config/config'

export const AuthuProtectedRoute = ({ children, roles = ['user'] }) => {
  const userLoginDetailsLocal = localStorage.getItem('userLoginDetails')
  const userLoginDetails = userLoginDetailsLocal ? JSON.parse(userLoginDetailsLocal) : null
  const userToken = localStorage.getItem("userToken")

  if (!checkEmpty(userLoginDetailsLocal) || !checkEmpty(userToken)) {
    return <Navigate to="/login" />
  } else {
    return children
  }
}


export const ChatProtectedRoute = ({ children, roles = ['user'] }) => {
  if (config?.environment && config?.environment === "prod") {
    return <Navigate to="/" />
  } else {
    return children
  }
}


