
import { Box, Link, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { displayItem, headingColor } from 'src/palette'
import CarouselItem from 'react-grid-carousel';
import { Show } from 'src/Helpers/Show';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PARTNER_LIST = [
  { image: '/assets/img/partner/kirusa.png', width: 180, height: 60 },
  { image: '/assets/img/partner/AUK.png', width: 180, height: 60 },
  { image: '/assets/img/partner/clinicsoncloud.png', width: 135, height: 60 },
  { image: '/assets/img/partner/senzmate.png', width: 220, height: 60 },
  { image: '/assets/img/partner/spectra.png', width: 180, height: 60 },
  { image: '/assets/img/partner/sheeltron.png', width: 200, height: 60 }
]
const responsiveLayout = [
  {
    breakpoint: 1000,
    cols: 2,
    rows: 2,
    gap: 20,
    loop: true,
    autoplay: 3000
  },
  {
    breakpoint: 1200,
    cols: 3,
    rows: 2,
    gap: 20,
    loop: true,
    autoplay: 3000
  },
]

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 6,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1400, min: 767 },
    items: 4,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

function Home() {
  const [teamList, setTeamList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  useEffect(() => {
    setTeamList([
      { name: 'Limton Xavier', company: 'Inovatrik Technologies', position: 'Co-Founder', image: "/assets/img/team/limton.png", linkeldinLink: 'https://www.linkedin.com/in/limtonxavier/' },
      { name: 'UthiraMoorthy', company: 'Inovatrik Technologies', position: 'Co-Founder', image: "/assets/img/team/moorthy.png", linkeldinLink: 'https://www.linkedin.com/in/uthiramoorthy/' },
      { name: 'Ruban Kanapathippillai', company: 'Inovatrik Technologies', position: 'Chief Advisor', image: "/assets/img/team/ruban.png", linkeldinLink: 'https://www.linkedin.com/in/rubankk/' },
      { name: 'Abhay Agarwal', company: 'Clinics on Cloud | Inovatrik', position: 'CEO | Director', image: "/assets/img/team/abhay.jpeg", linkeldinLink: 'https://www.linkedin.com/in/abhayagarwal03/' },
      // { name: 'Vivekanand', company: 'Excolotechnology', position: 'Ent-Architect', image: "/assets/img/team/vivek.png", linkeldinLink: '' },
      { name: 'Edwin Maljames', company: 'Abnormal Security', position: 'Engineer Leader', image: "/assets/img/team/edwin.png", linkeldinLink: 'https://www.linkedin.com/in/edwinmaljames/' },
      { name: 'Joseph Jude', company: 'Net Solution', position: 'CTO & Coach', image: "/assets/img/team/joseph.png", linkeldinLink: 'https://www.linkedin.com/in/jjude/' },
      { name: 'Elanchezhian', company: 'Arus', position: 'Founder & CEO', image: "/assets/img/team/elan.png", linkeldinLink: 'https://www.linkedin.com/in/chels/' },
      // { name: 'Krishna Kumar',company:'', position: 'Business Startegist', image: "/assets/img/team/8.png", linkeldinLink : '' },
      { name: 'Pratap Samuel', company: 'Honeywell Technology', position: 'Retired President', image: "/assets/img/team/PraSam.png", linkeldinLink: 'https://www.linkedin.com/in/pratap-samuel-32a410a8/' },
      { name: 'Mahendran Kathiresan', company: 'Circularity', position: 'Co-Founder and Business Head', image: "/assets/img/team/mahen.jpg", linkeldinLink: 'https://www.linkedin.com/in/mahendran-kathiresan-mahen-0324a74/' },
      { name: 'Dr.Venugopala Bheemanathini', company: 'RMS Health System, US', position: 'Nephrology Specialist', image: "/assets/img/team/Bheemanathini.png", information: 'https://rmccares.org/physicians/venugopala-bheemanathini-md/' },
      { name: 'Dr.R.Murugan', company: 'NIT Silchar, Assam', position: 'Assistant Professor I, ECE Dept.,', image: "/assets/img/team/murugan.png", linkeldinLink: 'https://www.linkedin.com/in/dr-murugan-r-316b892a/' },
    ])
    setPartnerList(PARTNER_LIST)
  }, [])


  return (
    <>
      <section id="team" className="team-area ptb-110" onMouseDown={(e) => e.preventDefault()} >
        <Box component="div" className="container">
          <Box component="div" className="section-title">
            <Box component="h2">
              Introducing Our Team: Enthusiastic about the Possibilities of{" "}
              <Box component="b" sx={headingColor()}>Artificial Intelligence.</Box>
            </Box>
            <Box component="p">
              We are mentors, architects, and doctors collaborating to explore the
              effective utilization of AI in healthcare.
            </Box>
          </Box>
          <Box component="div" className="row">
            <CarouselItem cols={4} rows={1} gap={14} responsiveLayout={responsiveLayout} loop hideArrow={teamList.length <= 10} autoplay={3000}>
              {teamList.map((team) => (
                <CarouselItem.Item key={team.name}>
                  {/* <Box component="div" className="col-lg-3 col-md-6 col-sm-6" key={team.name}> */}
                  <Box component="div" className="single-team-box" onMouseDown={(e) => e.preventDefault()}>
                    <Box component="div" className="image">
                      <img draggable={false} src={team.image} alt="team" />
                      <Box component="div" className="social">
                        {/* <Link underline="none" to="#" target="_blank">
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link underline="none" to="#" target="_blank">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link underline="none" to="#" target="_blank">
                        <i className="fab fa-instagram" />
                      </Link> */}
                        <Show.When isTrue={team.linkeldinLink !== '' && team.linkeldinLink !== null && team.linkeldinLink !== undefined}>
                          <Link underline="none" href={team.linkeldinLink} target="_blank">
                            <i className="fab fa-linkedin" />
                          </Link>
                        </Show.When>
                        <Show.When isTrue={team.information !== '' && team.information !== null && team.information !== undefined}>
                          <Link underline="none" href={team.information} target="_blank">
                            <i className="fa-solid fa-circle-info" />
                          </Link>
                        </Show.When>
                      </Box>
                    </Box>
                    <Box component="div" className="content" sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                      <Stack direction={'column'}>
                        <Box component="h3">{team.name}</Box>
                        <Box component="span">{team.position}</Box>
                        <Box component="h5">{team.company}</Box>
                      </Stack>
                    </Box>
                  </Box>
                  {/* </Box> */}
                </CarouselItem.Item>
              ))}
            </CarouselItem>
          </Box>
        </Box>
      </section>
      <Box component="div" >
        <Box component="div">
          <Box component="div" className="section-title">
            <Box component="h2">
              Our <Box component="b" sx={headingColor()}>Partner's</Box>
            </Box>
            {/* <Box component="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</Box> */}
          </Box>
          {/* <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ margin: 5, marginTop: 0 }} spacing={5}>
            <Carousel cols={6} rows={1} gap={0} loop scrollSnap hideArrow={true} spacing={3}>
              {partnerList.map((list) => (
                <Carousel.Item key={list.image}>
                  <Box sx={{ justifyContent: 'center', display: 'flex', margin: 1 }} onMouseDown={(e) => e.preventDefault()}>
                    <img draggable={false}
                      src={list.image}
                      alt='team'
                      width={list.width}
                      height={list.height}
                    />
                  </Box>
                </Carousel.Item>
              ))}
            </Carousel>

          </Stack> */}
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ margin: 5, marginTop: 0 }} spacing={5}>
            <Carousel
              swipeable={true}
              draggable={true}
              // showDots={true}
              responsive={responsive}
              ssr={true}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType='mobile'
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {partnerList.map((list) => (
                <Box component={'div'} sx={{ justifyContent: 'center', display: 'flex', margin: 1 }} onMouseDown={(e) => e.preventDefault()} key={'list'}>
                  <img draggable={false}
                    src={list.image}
                    alt='team'
                    width={list.width}
                    height={list.height}
                  />
                </Box>
              ))}
            </Carousel>
          </Stack>
        </Box>
      </Box >
    </>

  )
}

export default Home;