import { screenSize, getColor, padding, displayItem, size, border, margin, transition, headingColor } from 'src/palette';
import useMediaQuery from '@mui/material/useMediaQuery';

export function openButtonStyle(color = 'buttoncolor') {
    return {
        background: getColor(color),
        padding: 1, "&:hover": {
            background: getColor(color)
        },
        fontWeight: 700,
        borderRadius: 20,
        ...padding({ r: 4, l: 4, a: 1 })
    }
}

export function modalStyle() {
    return {
        position: 'absolute',
        top: screenSize('28%', '28%', '50%', '50%', '50%'),
        left: screenSize('28%', '28%', '50%', '50%', '50%'),
        // width: screenSize('95%', '95%', '90%', '80%', '60%'),
        height: screenSize('85%', '80%', '73%', '73%', '73%'),
        transform: screenSize('translate(-25%, -25%)', 'translate(-25%, -25%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)'),
        backgroundColor: '#212529',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
        borderRadius: '20px',
        overflow: 'auto',
        padding: padding({ a: 5 }),
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
        '&::-webkit-scrollbar': {
            width: '0px', // Hide scrollbar for Chrome, Safari, and Opera
        },
    };
}


export function bannerBoxStyle() {
    return {
        ...size('100%', '100%'),
        ...padding({ a: 4 }),
        background: getColor('glassColor'),
        borderRadius: '50px',
        border: `1px solid ${getColor()}`,
        // borderImage: `${getColor()}`,
        '&:hover': {
            ...border(1),
            ...transition(15, 1),
            cursor: 'pointer',
            boxShadow: `0 0 7px 3px ${getColor()}`
        }
    }

}
