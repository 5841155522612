import { Typography, Container, Grid, Stack, Box, } from "@mui/material";
import { url } from "src/apiUtils/apiPath";
import DashboardCount from './DashboardCount';


export default function Dashboard() {

    const dashboarData = [

        {
            title: "Total Blogs",
            total: 10,
            //   color: "error",
            icon: 'mingcute:book-2-line',
            //   navigateUrl: '/admin/booking'
        },
        {
            title: "Todays Blogs",
            total: 10,
            icon: 'mingcute:book-2-line',
            //   navigateUrl: '/admin/booking'
        },

        {
            title: `Total Users`,
            total: 10,
            //   color: "warning",
            icon: 'mingcute:user-4-line',
            //   navigateUrl: '/admin/studio'
        },
        {
            title: "Total Users",
            total: 10,
            //   color: "info",
            icon: 'mingcute:group-3-line',
            //   navigateUrl: '/admin/users'
        },
        {
            title: "Total Users",
            total: 10,
            //   color: "info",
            icon: 'mingcute:group-3-line',
            //   navigateUrl: '/admin/users'
        }
    ]
    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    {dashboarData.map((data) =>
                        <Grid item xs={12} sm={6} md={2.4}>
                            <DashboardCount
                                title={data.title}
                                total={data.total ?? 0}
                                // color={data.color}
                                icon={data.icon}
                            // navigateUrl={data.navigateUrl}
                            />
                        </Grid>
                    )}
                </Grid>
                {/* <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5} xl={7.5}>
                    <Stack direction={'column'}>
                        <Box>
                            <BookingOverall
                                title="Overall Booking"
                                chartLabels={chartLabels}
                                chartData={chartData}
                            />
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <TodaysBooking
                                title="Today's Booking"
                                chartLabels={chartBarLabel}
                                chartData={chartPlotData}
                            />
                        </Box>
                    </Stack>
                </Grid>

                <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
                    <Stack direction={'column'}>
                        <Box>
                            <ExpertListDetail
                                title="Upcoming Booking Details"
                                borderColor={"error"}
                                pieDataList={pieData}
                            />
                        </Box>
                        <Box sx={{ marginTop: 1 }}>
                            <BookingListDetails
                                title="Todays Booking List"
                                borderColor={"success"}
                            />
                        </Box>
                        <Box sx={{ marginTop: 1 }}>
                            <PaymentListDetails
                                title="Transaction Details"
                                borderColor={"info"}
                            />
                        </Box>
                    </Stack>

                </Grid> */}
            </Container>
        </>
    )
}