import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/css/bootstrap.min.css"
import "./assets/css/animate.min.css"
import "./assets/css/fontawesome.min.css"
import "./assets/css/boxicons.min.css"
import "./assets/css/flaticon.css"
import "./assets/css/owl.carousel.min.css"
import "./assets/css/owl.theme.default.min.css"
import "./assets/css/magnific-popup.min.css"
import "./assets/css/nice-select.css"
import "./assets/css/meanmenu.css"
import "./assets/css/progresscircle.min.css"
import "./assets/css/odometer.min.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import "./assets/css/dark-style.css"
import "./assets/css/responsivemui.css"
import "./assets/css/chat.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
