import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { ImageDrop } from 'quill-image-drop-module';

// Quill.register('modules/imageDrop', ImageDrop);
// Quill.register('modules/imageResize', ImageResize);

QuilEditor.propTypes = {
    inputValue: PropTypes.string,
    updateInputValue: PropTypes.func,
    others: PropTypes.object
};
export default function QuilEditor({ inputValue = '', updateInputValue, others = {} }) {
    console.log('testing ehe wrw ew eereqwrq', inputValue)
    const [modules] = useState({
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ size: [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image', 'video'],
            // [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        },
        // imageResize: {
        //     parchment: Quill.import('parchment'),
        //     modules: ['Resize', 'DisplaySize', 'Toolbar'],
        // },
        // imageDrop: true
    });

    const [formats] = useState([
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        // 'link',
        // 'image',
        // 'video'
    ]
    );

    const [text, setText] = useState(inputValue);

    const handleChange = (value) => {
        console.log(value);
        setText(value);
        updateInputValue(value)
    }

    useEffect(() => {
        setText(inputValue)
    }, [inputValue])

    return (
        <Box sx={{ ...others, width: '100%', padding: 1, height: '75vh' }}>
            <ReactQuill
                value={text}
                modules={modules}
                formats={formats}
                onChange={handleChange}
                className='quil-editor-custom-class'
                // style={{
                //     backgroundColor: getColor('white'),
                //     color: '#000000'
                // }}
                style={{ height: '100%' }}
            />
        </Box>
    );
}
