
import { Box, Button } from '@mui/material';
import { commonButtonStyle, headingColor } from 'src/palette'

function AboutSection() {
  const jumpToReleventDiv = (id = 'contact') => {
    const releventDiv = document.getElementById(id);
    if (releventDiv) {
      releventDiv.scrollIntoView({ behavior: "smooth" });
    }
    // localStorage.removeItem('jumptoelement')
  }

  return (
    <Box component="div" className="ai-solution-area  pt-100 pb-70" id='about'  onMouseDown={(e) => e.preventDefault()} >
      <Box component="div" className="container">
        <Box component="div" className="section-title">
          <Box component="h2">
            About<Box component="b" sx={headingColor}> Us</Box>
          </Box>
          {/* <Box component="p">
                We at Inolabs aim to strengthen the healthcare sector through an innovative AI-centric approach
              </Box> */}
        </Box>
        <Box component="div" className="row justify-content-center align-items-center">
          <Box component="div" className="col-lg-6 col-md-12">
            <Box component="div" className="ai-solution-wrap-image" onMouseDown={(e) => e.preventDefault()}>
              <img draggable={false} src="/assets/img/ai-solution/solution.png" alt="about" />
            </Box>
          </Box>
          <Box component="div" className="col-lg-6 col-md-12">
            <Box component="div" className="ai-solution-content">
              <Box component="h3">
                {/* We offer support for new AI initiatives within the healthcare vertical. */}
                We at Inolabs aim to strengthen the healthcare sector through an innovative AI-centric approach
                {/* Analysis And Deep <Box component="span" sx={HEADING_COLOR}>Machine Learning</Box> Provider With Ai
                    Solution */}
              </Box>
              {/* <Box component="p">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                    faucibus pulvinar iaculis et eu arcu mauris euismod duis diam nunc
                    ultrices blandit montes quis id erat diam non rhoncus cras elit.
                  </Box> */}
              <Box className="list">
                <Box component="li">
                  <i className="fa-solid fa-check" /> Cutting-edge AI hardware and Platform
                </Box>
                <Box component="li">
                  <i className="fa-solid fa-check" /> Comprehensive Resource Library
                </Box>
                <Box component="li">
                  <i className="fa-solid fa-check" /> Expert Guidance and Support
                </Box>
              </Box>
              <Box component="div" className="solution-btn">
                <Button sx={commonButtonStyle()} onClick={() => { jumpToReleventDiv("contact") }}>
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutSection;