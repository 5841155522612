
import { Box, Link } from '@mui/material';

function Home() {
  return (
    <>
      <footer className="footer-area bg-wrap-color">
        <Box component="div" className="container" onMouseDown={(e) => e.preventDefault()} >
          <Box component="div" className="row">
            <Box component="div" className="col-lg-3 col-md-5 col-sm-12">
              <Box component="div" className="single-footer-widget">
                <Box component="div" className="logo">
                  <Link underline="none" to="#">
                    <img draggable={false} src="/assets/img/inovatrik-white-logo.png" alt="footer-image" height="50px" width="220px" />
                  </Link>
                  <Box component="p">
                    Experience the AI Healthcare Research and Concept Lab
                  </Box>
                </Box>
                {/* <Box component="ul" className="social">
                  <Box component="li">
                    <Link underline="none" to="#" target="_blank">
                      <i className="flaticon-facebook-letter-logo" />
                    </Link>
                  </Box>
                  <Box component="li">
                    <Link underline="none" to="#" target="_blank">
                      <i className="flaticon-twitter-black-shape" />
                    </Link>
                  </Box>
                  <Box component="li">
                    <Link underline="none" to="#" target="_blank">
                      <i className="flaticon-instagram-logo" />
                    </Link>
                  </Box>
                  <Box component="li">
                    <Link underline="none" to="#" target="_blank">
                      <i className="flaticon-youtube" />
                    </Link>
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <Box component="div" className="col-lg-1 col-md-0 col-sm-0">
            </Box>
            <Box component="div" className="col-lg-8 col-md-9 col-sm-12">
              <Box component="div" className="single-footer-widget">
                <Box component="h3">Contacts</Box>
                <Box component="div" className="row">
                  <Box component="div" className="col-lg-6 col-md-12 col-sm-12">
                    <Box component="ul" className="footer-contact-list">
                      <Box component="li">
                        <Box>NR Towers,HSR, Bangalore,KA-560087</Box>
                      </Box>
                      <Box component="li">
                        <Link underline="none" href="mail:hello@inolabs.ai">
                          <Box
                            className="__cf_email__"
                            data-cfemail="3e5b4851524a5f7e59535f5752105d5153"
                          >
                            hello@inolabs.ai
                          </Box>
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link underline="none" href="tel:+919008522866"> +91 90085 22866,&nbsp;</Link>
                        <Link underline="none" href="tel:+919741790001"> +91 97417 90001</Link>
                      </Box>
                    </Box>
                    <br />
                  </Box>
                  <Box component="div" className="col-lg-6 col-md-12 col-sm-12">
                    <Box component="ul" className="footer-contact-list">
                      <Box component="li">
                        <Box> 801 Southbend Ln, Vestavia Hills, AL 36216, US</Box>
                      </Box>
                      <Box component="li">
                        <Link underline="none" href="mail:hello@inolabs.ai">
                          <Box
                            className="__cf_email__"
                            data-cfemail="3e5b4851524a5f7e59535f5752105d5153"
                          >
                            hello@inolabs.ai
                          </Box>
                        </Link>
                      </Box>
                      <Box component="li">
                        <Link underline="none" href="tel:+12056369696"> +1 205-636-9696</Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component="div" className="copyright-area">
          <Box component="div" className="container">
            <Box component="div" className="row align-items-center">
              <Box component="div" className="col-lg-6 col-md-6 col-sm-6">
                <Box component="p">
                  © Inolabs is Proudly Owned by{" "}
                  <Link underline="none" href="https://www.inovatrik.com/" target="_blank">
                    Inovatrik
                  </Link>
                </Box>
              </Box>
              <Box component="div" className="col-lg-6 col-md-6 col-sm-6">
                <Box component="ul">
                  <Box component="li">
                    <Link underline="none" to="#">Privacy Policy&nbsp;</Link>
                  </Box>
                  <Box component="li">
                    <Link underline="none" to="#">Terms &amp; Conditions</Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component="div" className="footer-shape-1">
          <img draggable={false}
            src="/assets/img/artificial-intelligence/footer-shape-1.png"
            alt="footer-image"
          />
        </Box>
        <Box component="div" className="footer-shape-2">
          <img draggable={false}
            src="/assets/img/artificial-intelligence/footer-shape-2.png"
            alt="footer-image"
          />
        </Box>
        <Box component="div" className="lines">
          <Box component="div" className="line" />
          <Box component="div" className="line" />
          <Box component="div" className="line" />
        </Box>
      </footer >
      {/* <Box component="div" className="go-top">
        <i className="fas fa-arrow-up" />
        <i className="fas fa-arrow-up" />
      </Box> */}
    </>

  )
}

export default Home;