import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/Inbox';
import RssFeedIcon from '@mui/icons-material/RssFeed';
const navConfig = []
navConfig.admin = [
  {
    title: 'Dashboard',
    path: '/admin/home',
    icon: <DashboardIcon />,
  },
  {
    title: 'Admin',
    icon: <SupervisorAccountOutlinedIcon />,
    submenu: [
      {
        title: 'Blog',
        path: '/admin/blog',
        icon: <RssFeedIcon />,
      }
    ]
  },
  {
    title: 'Form Request',
    path: '/admin/request-details',
    icon: <InboxIcon />,
  },
]

export default navConfig;
