import { useEffect, useState } from 'react';

import BannerForm from "../BannerForm/BannerForm"
import { Avatar, Box, IconButton, AppBar, Drawer, Container, Toolbar, Typography, Link, Button, ListItem, List, Tooltip, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LogoutIcon from '@mui/icons-material/Logout';
import { getColor } from 'src/palette';
import BannerContactModal from "../BannerContactModal/BannerContactModal"

const drawerWidth = 320;




const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

HeaderSection.propTypes = {
  userData: PropTypes.object,
  updateUser: PropTypes.func
};

function HeaderSection({ userData, updateUser }) {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  console.log('loader', loader)
  const [isLogin, setIsLogin] = useState(false);
  const [loginUserData, setLoginUserData] = useState({});
  const [selected, setSelected] = useState('home')
  useEffect(() => {
    // const getLocalData = localStorage.getItem('jumptoelement')
    // console.log(getLocalData)
    // if (getLocalData) {
    //   jumpToReleventDiv(getLocalData)
    // }
    // localStorage.removeItem('jumptoelement')
    getLoginData()
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000)
  }, []);

  const getLoginData = () => {
    const getLocalData = localStorage.getItem('userLoginDetails')
    if (getLocalData !== '' && getLocalData !== null && getLocalData !== undefined) {
      const userDetails = JSON.parse(getLocalData)
      setIsLogin(true)
      setLoginUserData(userDetails)
      console.log('userDetails', userDetails)
    } else {
      setIsLogin(false)
      setLoginUserData({})
    }
  }
  // function to toggle between light and dark theme
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const jumpToReleventDiv = (id = 'home') => {
    setSelected(id)
    localStorage.setItem('jumptoelement', id)
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    if (releventDiv) {
      releventDiv.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate('/home')
    }
    setOpen(false)
    // localStorage.removeItem('jumptoelement')
  }

  const [headerSticky, setHeaderSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setHeaderSticky(false);
      if (window.scrollY > 100) {
        setHeaderSticky(true);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      setTimeout(() => {
        const getLocalData = localStorage.getItem('jumptoelement')
        console.log(getLocalData)
        if (getLocalData) {
          jumpToReleventDiv(getLocalData)
        }
        localStorage.removeItem('jumptoelement')
      }, 1000)
    };
  }, []);


  const navListItem = [

    { link: "home", title: 'Home', design: { color: selected === 'home' ? getColor() : 'white', fontWeight: 700 }, link: "home", title: 'Home' },
    { link: "about", title: 'About Us', design: { color: selected === 'about' ? getColor() : 'white', fontWeight: 700, width: '100px' }, link: "about", title: 'About Us' },
    { link: "service", title: 'Services', design: { color: selected === 'service' ? getColor() : 'white', fontWeight: 700 }, link: "service", title: 'Services' },
    { link: "solution", title: 'Solutions', design: { color: selected === 'solution' ? getColor() : 'white', fontWeight: 700 }, link: "solution", title: 'Solutions' },
    { link: "team", title: 'Team', design: { color: selected === 'team' ? getColor() : 'white', fontWeight: 700 }, link: "team", title: 'Team' },
    { link: "contact", title: 'Contact', design: { color: selected === 'contact' ? getColor() : 'white', fontWeight: 700 }, link: "contact", title: 'Contact' },
  ]

  useEffect(() => {
    const handleScroll = () => {
      navListItem.forEach((list) => {
        const element = document.getElementById(list.link === 'service' ? 'service-box' : list.link);
        const rect = element.getBoundingClientRect();

        // Check if the top of the element is within the viewport
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          setSelected(list.link);
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <AppBar position="fixed" elevation={0} sx={{ background: 'black' }}  onMouseDown={(e) => e.preventDefault()}>
        <Container maxWidth={'100%'} sx={{ padding: 1 }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, paddingTop: 1.5 }}>
              <Link underline="none" to="/">
                <img draggable={false} src="/assets/img/inovatrik-white-logo.png" alt="logo" height="50px" width="220px" />
              </Link>
            </Typography>
            <Box sx={{ display: { sm: 'none', xs: 'none', md: 'none', lg: 'flex', xl: 'flex' } }}>
              <nav>
                <List style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', alignItems: 'center' }}>
                  {navListItem.map((list) => (
                    <ListItem>
                      <Button sx={list.design} onClick={() => { jumpToReleventDiv(list.link) }} className="app-nav-color">{list.title}</Button>
                    </ListItem>
                  ))}
                  <ListItem>
                    <BannerContactModal buttonName={'Community'} handleScroll={() => setSelected('contact')} onClose={() => console.log('test')} />
                  </ListItem>
                  {/* <ListItem>
                    <Button sx={{ color: 'white', fontWeight: 700 }} onClick={() => { navigate('/blog') }} className="app-nav-color">
                      Blog
                    </Button>
                  </ListItem> */}
                  {/* <Box component="div" className="others-options" >
                    {isLogin ? <>
                      <Stack direction={'row'} spacing={3}>
                        <IconButton onClick={() => { navigate('/dashboard') }}>
                          <Avatar sx={BUTTON_STYLE}>{loginUserData?.initials}</Avatar>
                        </IconButton>
                        <Tooltip title="Logout" >
                          <IconButton onClick={() => {
                            localStorage.removeItem('userToken')
                            localStorage.removeItem('userLoginDetails')
                            updateUser()
                          }} sx={{ p: 0 }}>
                            <LogoutIcon sx={{ color: getColor('white') }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </>
                     :
                      <BannerForm updateUser={() => {
                        getLoginData()
                        updateUser()
                      }} buttonName={'Login/Signup'} navigateAdmin={false} />
                    }
                  </Box> */}
                </List>
              </nav>
            </Box>

            <Box sx={{ display: { sm: 'flex', xs: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
              <nav>
                <ListItem>
                  <MenuIcon onClick={() => { handleDrawerOpen() }} sx={{ marginRight: 1 }} />
                </ListItem>
              </nav>
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
      <Box>
        <Drawer
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
            position: 'relative',
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Box sx={{ backgroundColor: '#343a40', height: '100%', }}>
            <DrawerHeader>
              <Box sx={{ padding: 3 }}>
                <CloseIcon sx={{ color: getColor('white'), fontSize: '250%', cursor: 'pointer' }} onClick={handleDrawerClose} />
              </Box>
            </DrawerHeader>
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <img draggable={false} src="/assets/img/inovatrik-white-logo.png" alt="logo" height="50px" width="220px" />
            </Box>
            <Box sx={{ marginTop: '30%' }}>
              {/* <NavBar closeDrawer={() => { handleDrawerClose() }} /> */}
              <List style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'block', alignItems: 'center' }}>
                {navListItem.map((list) => (
                  <ListItem>
                    <Button sx={list.design} onClick={() => { jumpToReleventDiv(list.link) }} className="app-nav-color">{list.title}</Button>
                  </ListItem>
                ))}
                <ListItem>
                  <BannerContactModal buttonName={'Community'} onClose={(event) => setOpen(false)} handleScroll={() => setSelected('contact')} />
                </ListItem>
                {/* <ListItem>
                  <Button sx={{ color: 'white', fontWeight: 700 }} onClick={() => { navigate('/blog') }} className="app-nav-color">
                    Blog
                  </Button>
                </ListItem> */}
                {/* <ListItem>
                  <Button sx={{ color: 'white', fontWeight: 700 }} onClick={() => {

                    navigate('/blog')
                  }} className="app-nav-color">
                    Blog
                  </Button>
                </ListItem> */}
                {/* <ListItem>
                  {isLogin ? <>
                    <Stack direction={'row'} spacing={3}>
                      <IconButton onClick={() => { navigate('/dashboard') }}>
                        <Avatar sx={BUTTON_STYLE}>{loginUserData?.initials}</Avatar>
                      </IconButton>
                      <Tooltip title="Logout" >
                        <IconButton onClick={() => {
                          localStorage.removeItem('userToken')
                          localStorage.removeItem('userLoginDetails')
                          updateUser()
                        }} sx={{ p: 0 }}>
                          <LogoutIcon sx={{ color: getColor('white') }} />
                        </IconButton>
                      </Tooltip>
                    </Stack></> :
                    <BannerForm updateUser={() => {
                      getLoginData()
                    }} buttonName={'Login/Signup'} />
                  }
                </ListItem> */}
              </List>
            </Box>
          </Box>
        </Drawer>
      </Box>
      {loader ? <Box component="div" className="preloader">
        <Box component="div" className="spinner" />
      </Box> : <></>}
    </>

  )
}

export default HeaderSection;