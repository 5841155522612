import { Box, Stack, Typography } from "@mui/material";
import BlogView from './BlogView'
import BlogEdit from './BlogEdit'
// import BlogList from './BlogList'
import { useState } from 'react'

export default function Dashboard() {
    const [isBlogEdit, setIsBlogEdit] = useState(false)
    const [selectedBlog, setSelectedBlog] = useState({})
    const [formType, setFormType] = useState('')
    const [blogEditData, setBlogEditData] = useState({})

    const handleBlogEdit = (value, data) => {
        setFormType(value)
        if (isBlogEdit) {
            setIsBlogEdit(false)
        } else {
            setIsBlogEdit(true)
        }
        setBlogEditData(data)

    }
    return (
        <Box width={'100%'}>
            {isBlogEdit ? <BlogEdit blogEditData={blogEditData} formType={formType} handleBlogEdit={(value) => handleBlogEdit(value)} /> :
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }} width={'100%'}>
                    {/* <Box sx={{ width: { xs: '100%', sm: '100%', md: '20%', lg: '20%', xl: '20%' }, height: '100%' }}> */}
                        {/* <BlogList openSelectedBlog={(value) => {
                            setSelectedBlog(value)
                            console.log(value)
                        }} /> */}
                    {/* </Box> */}
                    <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }, height: '100%' }}>
                        <BlogView blogData={selectedBlog} handleBlogEdit={(value, data) => handleBlogEdit(value, data)} />
                    </Box>
                </Stack>}
        </Box>
    )
}