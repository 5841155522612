import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet } from "react-router-dom";
import ChatForm from './ChatForm';

const drawerWidth = 240;

function ChatLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none',
          },

          width: { sm: drawerWidth },
          flexShrink: 0,
          background: '#000000',
          height: '100vh'
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block', md: "flex", lg: "flex", xl: 'flex' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            background: '#000000',
            height: '100vh'
          }}
        >
          <Box sx={{ backgroundColor: '#000000', width: '100%', height: '100%' }}>
            <Box sx={{ padding: 2 }}>
              <img draggable={false} src="/assets/img/inovatrik-white-logo.png" alt="logo" height="40px" width="170px" />
            </Box>
            {drawer}
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, background: "#333333", height: '100vh' }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Outlet /> */}
        <ChatForm />
      </Box>
    </Box>
  );
}

ChatLayout.propTypes = {
  window: PropTypes.func,
};

export default ChatLayout;
