import { Box, Typography, Button, Grid, Skeleton, Stack, CircularProgress, IconButton } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { InputField, SingleSelect, PhoneInput } from "src/components/ChatComponent";
import { Show } from "src/Helpers/Show";
import { config } from 'src/config/config';
import { validator } from 'src/validation/validation';
import { appApi } from "src/apiUtils/AppApi";
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { getColor } from "src/palette";
import { GoogleGenerativeAI } from "@google/generative-ai";
import jsPDF from 'jspdf';

const genAI = new GoogleGenerativeAI(
    config.GEMINI_AI_KEY
);

const jsonData = {
    "day1": {
        "overall": {
            "calories": 200.0,
            "carbs": 200,
            "proteins": 1.50,
            "fats": 60,
            "fibre": 30
        },
        "breakfast": {
            "dish": "Poha with Peanuts and Coconut",
            "serving": "1 cup",
            "calories": 300,
            "carbs": 50,
            "proteins": 10,
            "fats": 10,
            "fibre": 5
        },
        "lunch": {
            "dish": "Fish Curry with Brown Rice",
            "serving": "1.5 cups",
            "calories": 500,
            "carbs": 7.0,
            "proteins": 40,
            "fats": 20,
            "fibre": 10
        },
        "dinner": {
            "dish": "Dal with Vegetable Curry and Roti",
            "serving": "2 Rotis, 1 cup Dal, 1 cup Vegetables",
            "calories": 600,
            "carbs": 60,
            "proteins": 50,
            "fats": 20,
            "fibre": 10
        },
        "morning_snacks": {
            "dish": "Fruit and Yogurt",
            "serving": "1 cup yogurt, 1/2 cup fruit",
            "calories": 150,
            "carbs": 20,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Roasted Chickpeas",
            "serving ": "1/2 cup",
            "calories": 250,
            "carbs": 40,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        }
    },
    "day2": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre": 30
        },
        "breakfast": {
            "dish": "Omelette with Vegetables",
            "serving": "2 eggs with 1/2 cup vegetables",
            "calories": 350,
            "carbs ": 10,
            "proteins": 25,
            "fats": 20,
            "fibre": 5
        },
        "lunch": {
            "dish": "Prawn Biryani with Raita",
            "serving": "1.5 cups Biryani, 1/2 cup Raita",
            "calories ": 550,
            "carbs": 80,
            "proteins": 45,
            "fats": 20,
            "fibre": 10
        },
        "dinner": {
            "dish": "Moong Dal Cheela with Vegetable Salad",
            "serving": "2 Cheelas, 1 cup Salad ",
            "calories": 500,
            "carbs": 60,
            "proteins": 30,
            "fats": 15,
            "fibre": 15
        },
        "morning_snacks": {
            "dish": "Apple with Peanut Butter",
            "serving": "1 medium Apple, 2 tbsp Peanut Butter",
            "calories": 250,
            "carbs": 30,
            "proteins": 10,
            "fats": 10,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Sprouts Salad",
            "serving": "1 cup",
            "calories ": 250,
            "carbs": 40,
            "proteins": 10,
            "fats": 5,
            "fibre": 10
        }
    },
    "day3": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre": 30
        },
        "breakfast": {
            "dish": "Upma with Vegetables",
            "serving": "1 cup",
            "calories": 300,
            "carbs": 50,
            "proteins": 10,
            "fats ": 10,
            "fibre": 5
        },
        "lunch": {
            "dish": "Fish Fry with Salad and Brown Rice",
            "serving": "1 piece Fish, 1 cup Salad, 1 cup Rice",
            "calories": 500,
            "carbs": 70,
            "proteins": 40,
            "fats": 20,
            "fibre": 10
        },
        "dinner": {
            "dish": "Masoor Dal with Lauki Sabzi and Roti",
            "serving": "2 Rotis, 1 cup Dal, 1 cup Sabzi",
            "calories": 600,
            "carbs": 60,
            "proteins": 50,
            "fats": 20,
            "fibre": 10
        },
        "morning_snacks": {
            "dish": "Banana and Almonds",
            "serving": "1 Banana, 10 Almonds",
            "calories": 20.0,
            "carbs": 30,
            "proteins": 5,
            "fats": 5,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Vegetable Soup",
            "serving": "1 bowl",
            "calories": 200,
            "carbs": 30,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        }
    },
    "day4": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre ": 30
        },
        "breakfast": {
            "dish": "Scrambled Eggs with Toast",
            "serving": "2 eggs, 1 slice whole wheat toast",
            "calories": 350,
            "carbs": 20,
            "proteins": 25,
            "fats": 20,
            " fibre": 5
        },
        "lunch": {
            "dish": "Chicken Curry with Vegetable Pulao",
            "serving": "1.5 cups Pulao, 1 cup Curry",
            "calories": 550,
            "carbs": 80,
            "proteins": 45,
            "fats": 2.0,
            "fibre": 10
        },
        "dinner": {
            "dish": "Palak Paneer with Roti and Salad",
            "serving": "2 Rotis, 1 cup Palak Paneer, 1 cup Salad",
            "calories": 500,
            "carbs": 60,
            " proteins": 30,
            "fats": 15,
            "fibre": 15
        },
        "morning_snacks": {
            "dish": "Yogurt with Berries",
            "serving": "1 cup Yogurt, 1/2 cup Berries",
            "calories": 200,
            "carbs": 2.0,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Roasted Peanuts",
            "serving": "1/2 cup",
            "calories": 250,
            "carbs": 40,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        }
    },
    "day5": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre": 30
        },
        "breakfast": {
            "dish": "Idli with Sambar and Chutney",
            "serving": "2 Idlis, 1 cup Sambar, 1 tbsp Chutney",
            "calories": 300,
            "carbs": 50,
            "proteins": 10,
            "fats ": 10,
            "fibre": 5
        },
        "lunch": {
            "dish": "Fish Curry with Brown Rice",
            "serving": "1.5 cups Rice, 1 cup Curry",
            "calories": 500,
            "carbs": 70,
            "proteins": 40,
            "f ats": 20,
            "fibre": 10
        },
        "dinner": {
            "dish": "Chana Masala with Roti and Salad",
            "serving": "2 Rotis, 1 cup Chana Masala, 1 cup Salad",
            "calories": 600,
            "carbs": 60,
            "proteins": 50,
            "fats": 20,
            "fibre": 10
        },
        "morning_snacks": {
            "dish": "Fruit and Cottage Cheese",
            "serving": "1 cup Cottage Cheese, 1/2 cup Fruit",
            "calories": 150,
            "car bs": 20,
            "proteins": 15,
            "fats": 5,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Makhana (Fox Nuts)",
            "serving": "1/2 cup",
            "calories": 250,
            "carbs": 40,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        }
    },
    "day6": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre": 30
        },
        "breakfast": {
            "dish": "Omelette with Vegetables",
            "serving": "2 eggs with 1/2 cup vegetables",
            "calories": 350,
            "carbs": 10,
            "proteins": 25,
            "fats": 20,
            "fibre": 5
        },
        "lunch": {
            "dish": "Prawn Biryani with Raita",
            "serving": "1.5 cups Biryani, 1/2 cup Raita",
            "calories": 550,
            "carbs": 80,
            "proteins": 45,
            "fats": 20,
            "fibre": 10
        },
        "dinner": {
            "dish": "Moong Dal Cheela with Vegetable Salad",
            "serving": "2 Cheelas, 1 cup Salad",
            "calories": 500,
            "carbs": 60,
            "proteins": 30,
            "fats": 15,
            "fibre": 15
        },
        "morning_snacks": {
            "dish": "Apple with Peanut Butter",
            "serving": "1 medium Apple, 2 tbsp Peanut Butter",
            "calories": 250,
            "carbs": 30,
            "proteins": 10,
            "fats": 10,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Sprouts Salad",
            "serving": "1 cup",
            "calories": 250,
            "carbs": 40,
            "proteins": 10,
            "fats": 5,
            "fibre": 10
        }
    },
    "day7": {
        "overall": {
            "calories": 2000,
            "carbs": 200,
            "proteins": 150,
            "fats": 60,
            "fibre": 30
        },
        " breakfast": {
            "dish": "Upma with Vegetables",
            "serving": "1 cup",
            "calories": 300,
            "carbs": 50,
            "proteins": 10,
            "fats": 10,
            "fibre": 5
        },
        "lunch": {
            "dish": "Fish Fry with Salad and Brown Rice",
            "serving": "1 piece Fish, 1 cup Salad, 1 cup Rice",
            "calories": 500,
            "carbs": 70,
            "proteins": 40,
            "fats": 20,
            "fibre": 10
        },
        "dinner ": {
            "dish": "Masoor Dal with Lauki Sabzi and Roti",
            "serving": "2 Rotis, 1 cup Dal, 1 cup Sabzi",
            "calories": 600,
            "carbs": 60,
            "proteins": 50,
            "fats": 20,
            "fibre": 10
        },
        "morning_snacks": {
            "dish": "Banana and Almonds",
            "serving": "1 Banana, 10 Almonds",
            "calories": 200,
            "carbs": 30,
            "proteins": 5,
            "fats": 5,
            "fibre": 5
        },
        "evening_snacks": {
            "dish": "Vegetable Soup",
            "serving": "1 bowl",
            "calories": 200,
            "carbs": 30,
            "proteins": 10,
            "fats": 5,
            "fibre": 5
        }
    }
}


const chatApiUrl = config.chatApiUrl;
const cursine_type = [
    "North Indian",
    "South Indian",
    "East Indian",
    "West Indian",
    "Central Indian",
    "Punjabi Cuisine",
    "Gujarati Cuisine",
    "Bengali Cuisine",
    "Goan Cuisine",
    "Rajasthani Cuisine",
    "Maharashtrian Cuisine",
    "Kerala Cuisine",
    "Tamil Cuisine",
    "Andhra Cuisine",
    "Karnataka Cuisine",
    "Oriya Cuisine",
    "Assamese Cuisine",
    "Chettinad Cuisine",
    "Kashmiri Cuisine",
    "Hyderabadi Cuisine",
    "Lucknowi Cuisine",
    "Awadhi Cuisine",
    "Sindhi Cuisine",
    "Malvani Cuisine",
    "Coorgi Cuisine",
    "Naga Cuisine",
    "Manipuri Cuisine",
    "Sikkimese Cuisine",
    "Uttaranchali Cuisine",
    "Kumaoni Cuisine",
    "Garhwali Cuisine",
    "Jammu Cuisine",
    "Anglo-Indian Cuisine",
    "Parsi Cuisine",
    "Tibetan Cuisine",
    "Mughlai Cuisine",
    "Bihari Cuisine",
    "Jain Cuisine",
    "Chhattisgarhi Cuisine",
    "Konkani Cuisine",
    "Ladakhi Cuisine",
    "Rampuri Cuisine",
    "Bhojpuri Cuisine",
    "Karwari Cuisine",
    "Sindhi Cuisine",
    "Kutchi Cuisine",
    "Koshti Cuisine",
    "Banjara Cuisine",
    "Koli Cuisine",
    "Thakur Cuisine",
    "Arunachali Cuisine",
    "Manipur Cuisine",
    "Meghalayan Cuisine",
    "Mizo Cuisine",
    "Tripuri Cuisine",
];


const meal_types_list = [
    "vegetarian",
    "non-vegetarian",
    "vegan",
    "pescatarian",
    "flexitarian",
    "raw food",
    "ketogenic",
    "low carb",
    "Paleo",
    "Mediterranean",
    "Whole30",
]

export default function ChatForm() {
    const contentRef = useRef(null);
    const [chat, setChat] = useState('');
    const [chatAnswer, setChatAnswer] = useState('');
    const [loading, setLoading] = useState(true)
    const chatTextBoxRef = useRef(null);
    const [chatObject, setChatObject] = useState({
        cursine_type: '',
        health_condition: '',
        goal: '',
        weight: '',
        height: '',
        food_habit: '',
        gender: ''
    });
    const [validation, setValidation] = useState(false)
    const [checkLoginDataValid, setCheckLoginDataValid] = useState({
        cursine_type: [],
        weight: [],
        height: [],
        food_habit: [],
        gender: []
    })
    const generateRandomString = (length) => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset[randomIndex];
        }
        return result;
    }

    const generateSessionId = () => {
        const timestamp = new Date().getTime(); // Get current timestamp
        const randomString = generateRandomString(6); // Generate a random string of length 6
        const randomPart = Math.random().toString(36).substring(2, 8); // Additional random string
        return `${timestamp}-${randomPart}-${randomString}`;
    }
    useEffect(() => {
        setCheckLoginDataValid({
            cursine_type: validator({ value: chatObject.cursine_type, validate: ['required'] }),
            weight: validator({ value: chatObject.weight, validate: ['required'] }),
            height: validator({ value: chatObject.height, validate: ['required'] }),
            food_habit: validator({ value: chatObject.food_habit, validate: ['required'] }),
            gender: validator({ value: chatObject.gender, validate: ['required'] }),
        })
    }, [chatObject, validation])
    const [dietPlanObject, setDietPlanObject] = useState([])
    useEffect(() => {

        setDietPlanObject(Object.keys(jsonData))
    }, [])
    // const getAnswer = async (prompt) => {
    //     try {
    //         if (loading) {
    //             const sessionId = generateSessionId(); // Generate session ID
    //             console.log(sessionId); // Output session ID
    //             localStorage.setItem("chat_session_id", sessionId)
    //             setChatAnswer("")
    //             setLoading(false)
    //             // const data = { "prompt": chat };
    //             const data = { "prompt": prompt, "type": "diet_plan", "sessionId": sessionId };
    //             // const data = { "prompt": "Generate A diet plan for one week" };
    //             const Token = localStorage.getItem("userToken");
    //             fetch(`${chatApiUrl}stream-chat`, {
    //                 method: 'POST',
    //                 headers: {
    //                     "authorization": `Bearer ${Token}`,
    //                     "consumer_key": 'inolabai54321',
    //                     "Content-Type": "application/json"
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then(response => {
    //                     if (!response.ok) {
    //                         setValidation(false)
    //                         throw new Error('Network response was not ok');
    //                     }
    //                     const reader = response.body.getReader();

    //                     const answerData = []
    //                     function readStream() {
    //                         reader.read().then(({ done, value }) => {
    //                             if (done) {
    //                                 console.log('Stream finished');
    //                                 setLoading(true)
    //                                 return;
    //                          prompt   }
    //                             const chunk = new TextDecoder().decode(value);
    //                             if (chunk !== undefined && chunk !== null) {
    //                                 answerData.push(chunk)
    //                                 setChatAnswer(answerData.join(' '));
    //                                 console.log('Received chunk:', answerData.join(' '));
    //                             }
    //                             readStream();
    //                         }).catch(error => {
    //                             console.error('Error reading stream:', error);
    //                         });
    //                     }
    //                     readStream();
    //                     return;
    //                 })
    //                 .then(data => {
    //                     console.log(data);
    //                     // Continue processing the response data as needed
    //                 })
    //                 .catch(error => {
    //                     console.error('Error:', error);
    //                 })


    //             // Continue with other tasks concurrently without waiting for the streaming to complete
    //             console.log('Continuing with other tasks...');
    //         }

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const getAnswer = async (prompt) => {
        try {
            if (loading) {
                const answerData = []
                const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro-latest" });
                const req_promp = `${prompt}`
                const result = await model.generateContentStream(req_promp);
                console.log(result)
                for await (const chunk of result.stream) {
                    const chunkText = chunk.text();
                    console.log(chunkText);
                    answerData.push(chunkText)
                    setChatAnswer(answerData.join(' ').replaceAll("``` json", "").replaceAll("```", "").replaceAll("{", "\n{\n").replaceAll("}", "\n}").replaceAll("[", "\n[\n").replaceAll("]", "\n]").replaceAll(",", ",\n"));
                    console.log('Received chunk:', answerData.join(' '));
                }
                setLoading(true)
            }

        } catch (error) {
            console.error(error);
        }
    };

    const submitValidation = () => {
        setChatAnswer("")
        setValidation(true)
        let checkValid = false
        const keys = Object.keys(chatObject);
        keys.forEach((e) => {
            if (checkLoginDataValid[e]?.length > 0 && e !== 'otp') {
                checkValid = true
            }
        })
        if (checkValid) {
            return
        }
        setValidation(false)
        // const prompt = `Generate a diet plan 7 days based on height is ${chatObject.height} and weight is ${chatObject.weight} and cursine is ${chatObject.cursine_type} and food habit is ${chatObject.food_habit} and health condition is ${chatObject.health_condition} in full json format {days:{}}`
        // let prompt = `height is ${chatObject.height} and weight is ${chatObject.weight} and cursine is ${chatObject.cursine_type} and food habit is ${chatObject.food_habit} and gender is ${chatObject.gender}`
        // if (chatObject.health_condition) {
        //     prompt += `and health condition is ${chatObject.health_condition} `
        // }
        // if (chatObject.goal) {
        //     prompt += `and goal is ${chatObject.goal} `
        // }
        // prompt += ` based on give data generate a diet plan for 7 days and we will keep it as suggestion and we will consult professional dietitian also. it should be in json stringify format with the structure {day1 to day7 :{overall:{calories, carbs, proteins, fats, fibre} and {morningSnacks,breakfast, lunch, eveningSnacks, dinner}}}  with details of {dish, serving, calories, carbs, proteins, fats, fibre} if there is any mistake in the prompt don't consider that generate the diet plan based on other details in only json data for every day of 7 days and print only json format data not addition texts. modify this prompt to get only json data of diet plan in json format for full 7 days and dont stop in half until 7 days data to generate and dont add any disclaimer i understood everything and we consult a dietitian`
        const prompt = ` ${JSON.stringify(chatObject)} based on give data generate a diet plan for one week and we will keep it as suggesstion and we will consult professional also. it should be in json stringify format with the structure {day1 to day7 :{overall:{calories, carbs, proteins, fats, fibre} and {breakfast, lunch, dinner, morning_snacks, evening_snacks}}}  with details of {dish, serving, calories, carbs, proteins, fats, fibre} if there is any mistake in the prompt don't consider that generate the diet plan based on other details in only json data for every day of 7 days and print only json format data not addition texts. modify this prompt to get only json data of diet plan in json format for full 7 days and dont stop in half until 7 days data to generate and dont add any disclaimer i understood everything and we consult a dietitian in json value and generate for every day without fail`
        getAnswer(prompt)
    }

    useEffect(() => {
        // Scroll to the bottom of the chat text box when chatAnswer changes
        chatTextBoxRef.current.scrollTop = chatTextBoxRef.current.scrollHeight;
    }, [chatAnswer]);

    const stopStreaming = () => {
        const sessionId = localStorage.getItem("chat_session_id")
        if (!loading) {
            appApi(`${chatApiUrl}stop-streaming`, 'POST', { "Content-Type": "application/json" }, JSON.stringify({ sessionId }))
        }
    }

    // const generatePdf = () => {
    //     const content = contentRef.current;
    //     const pdf = new jsPDF();
    //     pdf.html(content, {
    //         callback: pdf => {
    //             pdf.save('converted-document.pdf');
    //         }
    //     });
    // }

    // const toProperCase = (str) => {
    //     return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    //         return match.toUpperCase();
    //     });
    // }

    return (
        <Box className="diet-form">
            <Stack direction={'row'} spacing={2}>
                <Box className="diet-input">
                    <Grid container spacing={1} direction={'column'}>
                        <Grid item>
                            <Typography>
                                Generate a diet plan for 7 days
                            </Typography>

                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Weight (in kg) *
                            </Typography>
                            <PhoneInput
                                value={chatObject.weight}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.weight}
                                placeholder='Enter weight'
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        weight: event
                                    }))
                                }}

                            />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Height (in cm) *
                            </Typography>
                            <PhoneInput
                                value={chatObject.height}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.height}
                                placeholder='Enter height'
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        height: event
                                    }))
                                }}

                            />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Cursine *
                            </Typography>
                            <SingleSelect
                                value={chatObject.cursine_type}
                                validate={validation}
                                placeholder='Enter Goal'
                                errorDetails={checkLoginDataValid?.cursine_type}
                                menuList={cursine_type}
                                id={'id'}
                                label={'data'}
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        cursine_type: event
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Gender *
                            </Typography>
                            <SingleSelect
                                value={chatObject.gender}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.gender}
                                menuList={["Male", "Female"]}
                                id={'id'}
                                label={'data'}
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        gender: event
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Food Habit *
                            </Typography>
                            <SingleSelect
                                value={chatObject.food_habit}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.food_habit}
                                menuList={meal_types_list}
                                placeholder='Enter Goal'
                                id={'id'}
                                label={'data'}
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        food_habit: event
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Health Condition
                            </Typography>
                            <InputField
                                value={chatObject.health_condition}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.health_condition}
                                placeholder='Enter Health Condition'
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        health_condition: event
                                    }))
                                }}

                            />
                        </Grid>


                        <Grid item>
                            <Typography sx={{ padding: 1 }}>
                                Goal
                            </Typography>
                            <InputField
                                value={chatObject.goal}
                                validate={validation}
                                errorDetails={checkLoginDataValid?.goal}
                                placeholder='Enter Goal'
                                onChange={(event) => {
                                    setChatObject((pre) => ({
                                        ...pre,
                                        goal: event
                                    }))
                                }}

                            />
                        </Grid>
                        <Grid item>
                            {/* <Show.When isTrue={!loading}>
                                <Box component={'span'} justifyContent={'center'}>
                                    <CircularProgress color="secondary" />
                                </Box>
                            </Show.When> */}
                            {/* <Show.When isTrue={loading}> */}
                            <Button onClick={submitValidation} className="diet-button" disabled={!loading}>
                                Submit
                            </Button>
                            {/* </Show.When> */}
                        </Grid>
                    </Grid>
                </Box>
                {/* <Show.When isTrue={chatAnswer}> */}
                <Box className="response-box">
                    <Box className="diet-text-copy">
                        Generate a 7 days diet plan
                        {/* <IconButton onClick={() => { stopStreaming() }} className="chat-IconButton" disabled={loading}>
                            <StopCircleOutlinedIcon sx={{ color: getColor('white'), opacity: loading ? 0.6 : 1 }} />
                        </IconButton> */}
                        {/* <Button onClick={() => { generatePdf() }} className="diet-button">
                            Generate PDF
                        </Button> */}
                    </Box>
                    <Box className="diet-diet-text" ref={chatTextBoxRef}>
                        <Box className="diet-text-response">
                            <Show.When isTrue={(loading || chatAnswer === null || chatAnswer === "" || chatAnswer === undefined)}>
                                {chatAnswer.split('\n').map((text, index) => (
                                    <Typography key={index}>
                                        {text}
                                    </Typography>
                                ))}
                            </Show.When>
                            {/* <Show.When isTrue={(loading || chatAnswer === null || chatAnswer === "" || chatAnswer === undefined)}></Show.When>
                            <div ref={contentRef}>
                                <div>
                                    {dietPlanObject.map((days) => (
                                        <>
                                            <p>{days}</p>
                                            <table className="pdf-table">
                                                <thead className="pdf-table-thead">
                                                    <th className="pdf-th">Title</th>
                                                    <th className="pdf-th">Dish</th>
                                                    <th className="pdf-th">Serving</th>
                                                    <th className="pdf-th">Calories</th>
                                                    <th className="pdf-th">Carbs</th>
                                                    <th className="pdf-th">Proteins</th>
                                                    <th className="pdf-th">Fats</th>
                                                    <th className="pdf-th">Fibre</th>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(jsonData[days])?.map((list) => (
                                                        <tr className="pdf-tr">
                                                            <td className="pdf-td">{toProperCase(list?.replaceAll("_", " "))}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.dish}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.serving}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.calories}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.carbs}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.proteins}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.fats}</td>
                                                            <td className="pdf-td">{jsonData[days][list]?.fibre}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </>

                                    ))}

                                </div>
                            </div>
                            <Show.When isTrue={(!loading && chatAnswer !== null && chatAnswer !== "" && chatAnswer !== undefined)}></Show.When> */}
                        </Box>
                    </Box>
                </Box>
            </Stack >
            {/* </Show.When> */}
        </Box >
    );
}

