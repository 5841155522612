import { useState, } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Modal, Box, Grid, Stack, IconButton, List, ListItem } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { screenSize, getColor, padding, displayItem, size, border, margin, transition, headingColor } from 'src/palette';
import { useNavigate } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import { openButtonStyle, bannerBoxStyle, modalStyle, bannerContactButtonStyle } from './style'

BannerForm.propTypes = {
    buttonName: PropTypes.string,
    onClose: PropTypes.func,
    handleScroll: PropTypes.func
};

const bannerBoxData = [
    {
        title: 'AI Contributor', image: 'assets/img/artificial-intelligence/industries/industries-1.png', type: 'ai_contributor',
        content: `An AI Contributor is someone who actively contributes to the field of Artificial Intelligence (AI). 
        This could involve creating new algorithms, developing innovative AI applications, publishing research papers, or sharing insights and knowledge through various channels such as blogs, conferences, or academic journals. 
        AI Contributors are instrumental in advancing the state-of-the-art in AI technology and driving innovation in the field.`
    },
    {
        title: 'AI Influencer', image: 'assets/img/artificial-intelligence/industries/industries-4.png', type: 'ai_influencer',
        content: `An AI Influencer is an individual who has a significant impact on the AI community and industry through their expertise, thought leadership, and influence. 
        They may have a large following on social media platforms, regularly contribute insightful content related to AI, and engage with audiences through speaking engagements, webinars, or workshops. 
        AI Influencers play a crucial role in shaping conversations, trends, and best practices in the AI ecosystem.`
    },
    {
        title: 'AI Learner', image: 'assets/img/artificial-intelligence/industries/industries-3.png', type: 'ai_learner',
        content:
            `An AI Learner is someone who is actively engaged in learning and acquiring knowledge and skills related to Artificial Intelligence. 
        This could include students pursuing AI-related degrees or certifications, professionals attending AI workshops or training programs, or individuals studying AI through online courses and self-study.
        AI Learners are passionate about expanding their expertise in AI and staying updated with the latest developments in the field.`
    }
]

const bannerBoxListData = {
    'ai_contributor': [
        'An AI Contributor is someone who actively contributes to the field of Artificial Intelligence (AI).',
        'This could involve creating new algorithms, developing innovative AI applications, publishing research papers, or sharing insights and knowledge through various channels such as blogs, conferences, or academic journals.',
        'AI Contributors are instrumental in advancing the state-of-the-art in AI technology and driving innovation in the field.'
    ],
    'ai_influencer': [
        'An AI Influencer is an individual who has a significant impact on the AI community and industry through their expertise, thought leadership, and influence.',
        'They may have a large following on social media platforms, regularly contribute insightful content related to AI, and engage with audiences through speaking engagements, webinars, or workshops.',
        'AI Influencers play a crucial role in shaping conversations, trends, and best practices in the AI ecosystem.'
    ],
    'ai_learner': [
        'An AI Learner is someone who is actively engaged in learning and acquiring knowledge and skills related to Artificial Intelligence.',
        'This could include students pursuing AI-related degrees or certifications, professionals attending AI workshops or training programs, or individuals studying AI through online courses and self-study.',
        'AI Learners are passionate about expanding their expertise in AI and staying updated with the latest developments in the field.'
    ],
}


export default function BannerForm({ buttonName, onClose, handleScroll }) {


    const [openModal, setOpenModal] = useState(false)
    const openLoginModal = () => {
        if (openModal === true) {
            setOpenModal(false)
            onClose(false)
        } else {
            setOpenModal(true)
            onClose(true)
        }
    }
    return (
        <>
            <Box>
                <Button type="submit" variant="contained"
                    onClick={() => openLoginModal()}
                    sx={openButtonStyle()}
                >
                    {buttonName}
                </Button>
            </Box>
            {openModal ? <LoginModal
                openModal={openModal}
                handleModel={() => {
                    openLoginModal()
                }}
                handleScroll={handleScroll}
            /> : <></>}
        </>
    );

}

LoginModal.propTypes = {
    openModal: PropTypes.bool,
    handleModel: PropTypes.func,
    handleScroll: PropTypes.func,
};


export function LoginModal({ openModal, handleModel, handleScroll }) {
    const navigate = useNavigate()
    const jumpToReleventDiv = () => {
        const releventDiv = document.getElementById('contact');
        if (releventDiv) {
            releventDiv.scrollIntoView({ behavior: "smooth" });
            handleScroll()
        }
    }
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={openModal}
        >
            <Box
            //  sx={{ ...modalStyle() }} 
             className="banner-contact-box">
                {/* <Stack direction={screenSize('column', 'column', 'row', 'row', 'row')}> */}
                <Box sx={{
                    width: '100%',
                    overflow: 'auto',
                    padding: 3,

                }}>
                    <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack sx={{ display: 'flex', justifyContent: screenSize('center', 'center', 'flex-end', 'flex-end', 'flex-end'), alignContent: 'flex-end' }} direction={'row'} spacing={3}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ ...openButtonStyle() }}
                                    onClick={() => {
                                        jumpToReleventDiv()
                                        handleModel()
                                    }}
                                >
                                    Join
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => { handleModel() }}
                                    sx={{
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        ...padding({ r: 4, l: 4, a: 1 })
                                    }}
                                    color='error'
                                >
                                    close
                                </Button>
                                {/* <IconButton
                                        onClick={() => { handleModel() }}
                                    >
                                        <CloseIcon sx={{
                                            color: getColor('white'),
                                            // position: 'absolute',
                                            fontSize: '150%'
                                        }}
                                        />
                                    </IconButton> */}
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        ...displayItem({ d: 'flex', jc: 'center', ai: 'center', ac: 'center' }),
                        height: '100%',
                        // screenSize('100%', '100%', '70%', '70%', '70%'),
                    }}>
                        <Grid container spacing={3} justifyContent={'center'}>
                            {bannerBoxData.map((titlelist) => (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={titlelist.title}>
                                    <Box
                                        sx={bannerBoxStyle()}
                                    // onClick={() => {
                                    //     jumpToReleventDiv('contact')
                                    //     handleModel()
                                    // }}
                                    >
                                        <Box sx={{ ...displayItem({ d: 'flex', jc: 'center' }) }}>
                                            <Typography variant='h4' sx={headingColor}>
                                                {titlelist.title}
                                            </Typography>
                                        </Box>
                                        <List>
                                            {bannerBoxListData[titlelist.type]?.map((list) => (
                                                // <ListItem key={list}>
                                                //     <DoneIcon sx={{ ...margin({ l: 2 }), color: getColor() }} />  {list}
                                                // </ListItem>
                                                <Typography align='justify' sx={{ ...margin({ l: 2, t: 2 }) }}><Typography component={'span'} sx={{ color: getColor() }}>&#8669;&nbsp;</Typography>{list}</Typography>
                                            ))}
                                        </List>
                                    </Box>

                                </Grid>
                            ))
                            }
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{
                                        ...displayItem({ d: 'flex', jc: 'flex-end', ai: 'flex-end', ac: 'flex-end' }),
                                        flexGrow: 1
                                    }}>
                                        <Button
                                            type="submit"
                                            variant="contained"

                                            sx={{ ...openButtonStyle(), ...margin({ t: 5 }) }}
                                        >
                                            Contact Us
                                        </Button>
                                    </Box>
                                </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                {/* </Stack > */}
            </Box >
        </Modal >
    );
}

