import { Box, Typography, TextField, Chip, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getColor, padding } from 'src/palette';




InputError.propTypes = {
    errorDetails: PropTypes.array,
    show: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    menuList: PropTypes.array,
    id: PropTypes.string,
    label: PropTypes.string
};
export default function InputError(
    {
        errorDetails,
        validate,
        onChange,
        value,
        disabled,
        placeholder,
        menuList,
        id,
        label
    }
) {
    const [isValid, setIsValid] = useState(true)
    const [msg, setMsg] = useState('')
    const [showValidation, setShowValidation] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setMessages()

    }, [errorDetails, validate])


    const setMessages = () => {
        const msg = errorDetails?.length > 0 ? errorDetails[0]?.msg : '';
        const validData = errorDetails[0]?.notValid ?? false;
        setMsg(msg)
        setIsValid(validData)
        setShowValidation(validate)
        setIsDisabled(disabled)

    }
    const INPUT_BOX_COLOR = {
        '& .MuiInputBase-input': {
            color: getColor('admin_primary'),
            border: `1px solid ${getColor('admin_primary')}`,
            borderRadius: 8,
            ...padding({ l: 4, r: 4, a: 2 })
        },
        '& .MuiOutlinedInput-root': {
            border: `1px solid ${getColor('admin_primary')}`,
            borderRadius: 8,
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('admin_primary')
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: getColor('admin_primary') // Change border color on hover
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: getColor('admin_primary') // Change border color on focus
        },
        opacity: isDisabled ? 0.6 : 1,
    };
    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <TextField
                error={isValid && showValidation}
                fullWidth
                select
                placeholder={placeholder}
                name="Email"
                value={value}
                sx={INPUT_BOX_COLOR}
                onChange={(event) => {
                    onChange(event.target.value)
                }}
                autoComplete={'off'}
                color='primary'
            >
                {menuList?.length > 0 ? menuList.map((e) => (
                    <MenuItem key={e[id || label]} value={e[id || label]}>{e[label]}</MenuItem>
                )) : <><MenuItem key={''} value={''}>{'None'}</MenuItem></>
                }

            </TextField>
        </Box>
        <Box sx={{ height: '10px', padding: 1 }}>
            {isValid && showValidation ? <Typography variant="subtitle" sx={{ color: 'red' }}>{msg}</Typography> : <></>}
        </Box>
    </>
    );
}
