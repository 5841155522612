import React from 'react';
import { Box } from '@mui/material';
import { headingColor } from 'src/palette';


const SolutionSection = () => {
    return (
        <Box component="div" className="features-wrap-area pt-100 pb-70" id='solution' onMouseDown={(e) => e.preventDefault()} >
            <Box component="div" className="container">
                <Box component="div" className="section-title">
                    <Box component="h2">
                        Possible <Box component="b" sx={headingColor()}>Healthcare</Box> Solutions
                    </Box>
                    {/* <Box component="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</Box> */}
                </Box>

                <Box component="div" className="row justify-content-center">
                    <Box component="div" className="col-lg-4 col-sm-6">
                        <Box component="div" className="features-wrap-card">
                            <Box component="div" className="features-image">
                                <img draggable={false} src="/assets/img/ai-solution/features/features-1.png" alt="banner" />
                            </Box>
                            <Box component="div" className="features-content">
                                <Box component="h3">Swift and Accurate Diagnostics</Box>
                                <Box component="p">With our advanced AI algorithms, we provide rapid and precise diagnostic reports, minimizing waiting times and ensuring accurate diagnoses for patients.</Box>
                                <br/>
                            </Box>
                        </Box>
                    </Box>

                    <Box component="div" className="col-lg-4 col-sm-6">
                        <Box component="div" className="features-wrap-card">
                            <Box component="div" className="features-image">
                                <img draggable={false} src="/assets/img/ai-solution/features/features-2.png" alt="banner" />
                            </Box>
                            <Box component="div" className="features-content">
                                <Box component="h3">Effective Treatment Planning</Box>
                                <Box component="p">We help healthcare professionals devise personalized treatment plans by leveraging AI technologies. Our algorithms analyze vast amounts of medical data to recommend the most effective courses of action.</Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box component="div" className="col-lg-4 col-sm-6">
                        <Box component="div" className="features-wrap-card">
                            <Box component="div" className="features-image">
                                <img draggable={false} src="/assets/img/ai-solution/features/features-3.png" alt="banner" />
                            </Box>
                            <Box component="div" className="features-content">
                                <Box component="h3">Neonatal Care 24/7</Box>
                                <Box component="p">We understand the criticality of neonatal care. Our AI-driven solutions provide round-the-clock support for newborns, ensuring their well-being and offering peace of mind to parents and healthcare providers.</Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box component="div" className="col-lg-4 col-sm-6">
                        <Box component="div" className="features-wrap-card">
                            <Box component="div" className="features-image">
                                <img draggable={false} src="/assets/img/ai-solution/features/radiology.svg" alt="banner" />
                            </Box>
                            <Box component="div" className="features-content">
                                <Box component="h3">AI Radiology for Early Detection</Box>
                                <Box component="p">Our AI-powered radiology solutions play a crucial role in early detection of critical conditions from breast cancer and lung cancer to neurological abnormalities and hidden fractures. By utilizing advanced algorithms and image analysis techniques, we enable faster and more accurate diagnoses, leading to improved patient outcomes.</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box component="div" className="col-lg-4 col-sm-6">
                        <Box component="div" className="features-wrap-card">
                            <Box component="div" className="features-image">
                                <img draggable={false} src="/assets/img/ai-solution/features/features-4.png" alt="banner" />
                            </Box>
                            <Box component="div" className="features-content">
                                <Box component="h3">Innovative Approach to Tumor Analysis</Box>
                                <Box component="p">Inolabs AI technology plays a significant role in analyzing different stages of tumors. By leveraging machine learning algorithms, we assist healthcare professionals in identifying tumor characteristics more efficiently, facilitating timely interventions for better patient care.</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SolutionSection;
