import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText, Button, Stack, Box, Divider, Drawer, Avatar, Typography, IconButton, Popover, Grid } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { NavLink as RouterLink } from 'react-router-dom';
import NavConfig from './Subcomponents/NavBar/NavConfig';
import { getColor, margin, padding } from 'src/palette';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const minDrawerWith = 70
const maxDrawerWith = 270
const Menu_select_color = getColor('glassColor');

const BUTTON_STYLE = {
    background: 'linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)',
    padding: 1, "&:hover": {
        background: "linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)"
    },
    color: 'white',
    fontWeight: 700,
    borderRadius: 20,
    paddingLeft: 2,
    paddingRight: 2
}

const Active_state = {
    color: getColor('white'),
    fontWeight: 'fontWeightBold',
    backgroundColor: Menu_select_color,
}

const inactive_state = {
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: getColor('white'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: 8,
    cursor: 'pointer',
    ...padding({ a: 2, l: 4 })
}

export function SideBar({ handleDrawerWidth }) {
    const navigate = useNavigate()
    const [drawerWidth, setDrawerWidth] = useState(minDrawerWith)
    const [loginUserData, setLoginUserData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        handleDrawerWidth(drawerWidth)
        getLoginData()
    }, [drawerWidth])
    const getLoginData = () => {
        const getLocalData = localStorage.getItem('userLoginDetails')
        if (getLocalData === '' || getLocalData === null || getLocalData === undefined) {
            navigate('/Home')
        } else {
            const userDetails = JSON.parse(getLocalData)
            setLoginUserData(userDetails)
        }
    }
    return (
        <Fragment>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        border: 'none',
                    },
                    position: 'relative',
                }}
                variant="persistent"
                anchor="left"
                open={true}
            >
                <Box sx={{ backgroundColor: getColor('admin_primary'), height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {!open &&
                        <>
                            <Box sx={{ padding: 2 }}>
                                <img draggable={false} src="/assets/img/inovatrikFavIcon.png" alt="logo" height="30px" width="70px" />
                            </Box><Divider sx={{ backgroundColor: getColor('white') }} />
                        </>
                    }
                    <Stack direction={'row'} justifyContent={open ? 'space-between' : 'center'} spacing={1} padding={2}>

                        {open &&
                            <Box>
                                <img draggable={false} src="/assets/img/inovatrik-white-logo.png" alt="logo" height="40px" width="170px" />
                            </Box>
                        }
                        <Box sx={{
                            // position: 'absolute',
                            display: 'flex',
                        }}>
                            <Box
                                sx={{
                                    ...padding({ a: 1 }),
                                    backgroundColor: getColor('white'),
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                    '&.hover': {
                                        backgroundColor: getColor('white'),
                                    },
                                }}
                                onClick={() => {
                                    if (open) {
                                        setOpen(false)
                                        setDrawerWidth(minDrawerWith)
                                        localStorage.setItem('menuOpen', false)
                                    } else {
                                        setOpen(true)
                                        setDrawerWidth(maxDrawerWith)
                                        localStorage.setItem('menuOpen', true)
                                    }
                                }}>
                                {open ? <ArrowCircleLeftOutlinedIcon /> : <ArrowCircleRightOutlinedIcon />}
                            </Box>
                        </Box>
                    </Stack>
                    <Divider sx={{ backgroundColor: getColor('white') }} />
                    <Box sx={{ flexGrow: 1 }}>
                        <NavBar open={open} />
                    </Box>
                    <Divider sx={{ backgroundColor: getColor('white') }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                        <Avatar sx={{ ...BUTTON_STYLE, ...(open ? { marginRight: 1 } : {}) }}>{loginUserData?.initials}</Avatar>
                        {open && <Typography variant="body1">{loginUserData.firstName} {loginUserData.lastName}</Typography>}
                    </Box>
                    {open ? <Box sx={{
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Button
                            onClick={() => {
                                localStorage.removeItem('userToken');
                                localStorage.removeItem('userLoginDetails');
                                localStorage.removeItem('getMainMenu')
                                localStorage.removeItem('getSubMenu')
                                navigate('/home');
                            }}
                            sx={{
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                background: getColor(),
                                "&:hover": {
                                    background: getColor()
                                },
                                color: 'white',
                                fontWeight: 700,
                                fontSize: '1.3rem',
                                borderRadius: 20,
                                ...padding({ l: 2, r: 2, a: 1 })
                            }}
                        >
                            <LogoutIcon sx={{ color: getColor('white'), ...(open ? { marginRight: 1 } : {}) }} />
                            {open && 'Logout'}
                        </Button>
                    </Box> :
                        <Box sx={{ ...margin({ a: 2 }) }}>
                            <IconButton onClick={() => {
                                localStorage.removeItem('userToken');
                                localStorage.removeItem('userLoginDetails');
                                localStorage.removeItem('getMainMenu')
                                localStorage.removeItem('getSubMenu')
                                navigate('/login');
                            }}
                            >
                                <LogoutIcon sx={{ color: getColor('white') }} />
                            </IconButton>
                        </Box>
                    }

                </Box>
            </Drawer >
        </Fragment>
    );
}

function NavBar({ type = 'admin', open }) {
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [selectedSubMenu, setSelectedSubMenu] = useState(null);
    const [NavData, setNavData] = useState(NavConfig[type]);

    useEffect(() => {
        // const getMainMenu = localStorage.getItem('getMainMenu')
        // const getSubMenu = localStorage.getItem('getSubMenu')
        handleSideMenu(NavData[0].title, null)
    }, [])


    const handleSideMenu = (menu = null, subMenu = null) => {
        setSelectedMenu(menu);
        setSelectedSubMenu(subMenu);
        localStorage.setItem('getMainMenu', menu)
        localStorage.setItem('getSubMenu', subMenu)
    }

    return (
        <List disablePadding sx={{ p: 1 }}>
            {NavData?.filter((menu) => (menu?.submenu?.length > 0 || (menu?.path !== '' && menu?.path !== null && menu?.path !== undefined))).map((item, index) => (
                <div key={index}>
                    {item?.submenu?.length > 0 ?
                        <SubMenu
                            key={index}
                            item={item}
                            selectedMenu={selectedMenu}
                            selectedSubMenu={selectedSubMenu}
                            handleSideMenu={handleSideMenu}
                            open={open}
                        />
                        :
                        <MainMenuItem
                            key={index}
                            item={item}
                            selectedMenu={selectedMenu}
                            handleSideMenu={handleSideMenu}
                            open={open}
                        />
                    }
                </div>
            ))}
        </List>
    );
}

function SubMenu({ item, selectedSubMenu, selectedMenu, handleSideMenu, open }) {
    const [expanded, setExpanded] = useState(false);
    const [hoveredSubmenu, setHoveredSubmenu] = useState(null);

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    useEffect(() => {
        if (selectedSubMenu === item.title) {
            toggleExpanded()
        }
    }, [])

    return (
        <Box>
            <Box
                onClick={toggleExpanded}
                sx={{
                    ...inactive_state,
                    ...((selectedMenu === item.title) ? Active_state : {}),
                    '&.hover': {
                        ...((selectedMenu === item.title) ? Active_state : {}),
                    },
                    ...(!open && { ...padding({ a: 2 }), borderRadius: 10 }),
                    ...(!open && { ...margin({ b: 1, t: 1 }) })
                }}
            >
                <Stack direction={open ? 'row' : 'column'} spacing={2} alignItems={'center'}>
                    <Box>{item.icon && item.icon}</Box>
                    {open && <Box>{item.title && item.title}</Box>}
                    {!open && <Box component={'span'}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>}
                </Stack>
                {open &&
                    (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            </Box>
            {expanded &&
                <Box sx={{ ...margin({ b: 1, t: 1 }) }}>
                    {item?.submenu?.map((submenu, index) => (
                        <SubMenuItem
                            key={index}
                            item={submenu}
                            selectedMenu={item.title}
                            handleSideMenu={() => handleSideMenu(item.title, submenu.title)}
                            selectedSubMenu={selectedSubMenu}
                            open={open}
                        />
                    ))}
                </Box>
            }
        </Box>
    );
}

function SubMenuItem({ item, selectedMenu, selectedSubMenu, handleSideMenu, open }) {
    const navigate = useNavigate();
    return (
        <Box sx={{ ...(open && { ...margin({ l: 3 }) }) }}>

            <Box
                onClick={() => {
                    navigate(item.path)
                    handleSideMenu(item.title)
                }}
                sx={{
                    ...inactive_state,
                    ...((selectedSubMenu === item.title) ? Active_state : {}),
                    // '&.hover': {
                    //     ...((selectedSubMenu === item.title) ? Active_state : {}),
                    // },
                    ...(!open && { ...padding({ a: 2 }), borderRadius: 10 })
                }}
            >

                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Box>{item.icon && item.icon}</Box>
                    {open && <Box>{item.title && item.title}</Box>}
                </Stack>

            </Box>
        </Box>
    );
}

function MainMenuItem({ item, selectedMenu, handleSideMenu, open }) {

    const navigate = useNavigate();
    return (
        <Box onClick={() => {
            if (selectedMenu !== item.title) {
                navigate(item.path)
                handleSideMenu(item.title)
            }
        }}
            componen
            sx={{
                ...inactive_state,
                ...((selectedMenu === item.title) ? Active_state : {}),
                '&.hover': {
                    ...((selectedMenu === item.title) && { background: Menu_select_color }),
                },
                ...(!open && { ...padding({ a: 2 }), borderRadius: 10 })
            }}
        >

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Box>{item.icon && item.icon}</Box>
                {open && <Box>{item.title && item.title}</Box>}
            </Stack>

        </Box>
    );
}

export default SideBar;