import { getColor, headingColor, screenSize } from 'src/palette';

const style = {
    modalStyle: {
        position: 'absolute',
        top: screenSize('28%', '28%', '50%', '50%', '50%'),
        left: screenSize('25%', '25%', '50%', '50%', '50%'),
        width: screenSize('95%', '95%', '80%', '80%', '80%'),
        height: screenSize('80%', '70%', '80%', '80%', '80%'),
        transform: screenSize('translate(-25%, -25%)', 'translate(-25%, -25%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)'),
        bgcolor: '#212529',
        boxShadow: 24,
        borderRadius: '20px',
        overflow: 'auto',
    },
    iconBox: {
        backgroundColor: '#343a40',
        width: screenSize('100%', '100%', '50%', '50%', '50%'),
        display: screenSize('none', 'none', 'flex', 'flex', 'flex'),
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        height: '100vh'

    },
    flexEnd: { display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end', flexGrow: 1 },
    flexStart: { display: 'flex', justifyContent: 'flex-start', alignContent: 'flex-start' },
    formBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginButton: {
        background: 'linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)',
        padding: 1,
        "&:hover": {
            background: "linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)"
        },
        color: getColor('white')

    }
}
export default style;