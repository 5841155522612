import { TableCell, TableRow, Box, Grid, Button, Typography, TablePagination, IconButton, Modal, Divider, Stack } from '@mui/material';
import { useEffect, useState, } from 'react';
import { SearchField, TableComponent, AdminInputField, AdminPhoneInput, AdminMultiSelect, AdminSingleSelect } from 'src/components'
import { getColor, padding, margin, headingColor } from 'src/palette';
import TableList from './TableList';
import PropTypes from 'prop-types';
import { Close as CloseIcon, ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon, ArrowCircleLeftOutlined as ArrowCircleLeftOutlinedIcon } from '@mui/icons-material';
import style from './style';
import { validator } from 'src/validation/validation';

FormModal.propTypes = {
    openModal: PropTypes.bool,
    handleModel: PropTypes.func,
};

export default function FormModal({ openModal, handleModel }) {
    const [validation, setValidation] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        multi: [],
        single: ''
    })
    const [checkFormDataValid, setCheckFormDataValid] = useState({
        name: [],
        phone: [],
        multi: [],
        single: []
    })

    const [menuList, setMenuList] = useState([
        { id: 1, data: 'testing1' },
        { id: 2, data: 'testing2' },
        { id: 3, data: 'testing3' }
    ])

    useEffect(() => {
        setCheckFormDataValid({
            name: validator({ value: formData.name, validate: ['required'] }),
            phone: validator({ value: formData.phone, validate: ['required'] }),
            multi: validator({ value: formData.multi, validate: ['required'] }),
            single: validator({ value: formData.single, validate: ['required'] }),
        })
    }, [validation])
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={openModal}
        >
            <Box sx={style.modalStyle}>
                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                    <Box sx={{ ...padding({ a: 2 }) }}>
                        <Typography variant='h5' sx={{ WebkitTextFillColor: '#3C0753' }}>Basic Component</Typography>
                    </Box>
                    {/* <IconButton
                        onClick={() => { handleModel() }}
                        sx={{ padding: 2 }}
                    > */}
                    <CloseIcon sx={{
                        color: getColor('admin_primary'),
                        fontSize: '170%',
                        cursor: 'pointer',
                        ...margin({ r: 2 })
                    }}
                        onClick={() => { handleModel() }}
                    />
                    {/* </IconButton> */}

                </Stack>
                <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', ...margin({ b: 3 }) }} />
                <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, width: '100%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AdminInputField
                            validate={validation}
                            errorDetails={checkFormDataValid?.name}
                            value={formData.name}
                            placeholder='Enter Name'
                            onChange={(event) => {
                                setFormData((pre) => ({
                                    ...pre,
                                    name: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AdminPhoneInput
                            validate={validation}
                            errorDetails={checkFormDataValid?.phone}
                            value={formData.phone}
                            placeholder='Enter Phonenumber'
                            onChange={(event) => {
                                setFormData((pre) => ({
                                    ...pre,
                                    phone: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AdminMultiSelect
                            validate={validation}
                            errorDetails={checkFormDataValid?.multi}
                            value={formData.multi}
                            menuList={menuList}
                            id={'id'}
                            label={'data'}
                            onChange={(event) => {
                                setFormData((pre) => ({
                                    ...pre,
                                    multi: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AdminSingleSelect
                            validate={validation}
                            errorDetails={checkFormDataValid?.single}
                            value={formData.single}
                            menuList={menuList}
                            id={'id'}
                            label={'data'}
                            onChange={(event) => {
                                setFormData((pre) => ({
                                    ...pre,
                                    single: event
                                }))
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ top: '90%', ...margin({ t: 1, b: 1 }) }}>
                    <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
                </Box>
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button sx={{
                        color: getColor('white'),
                        backgroundColor: getColor(),
                        ...padding({ a: 2, l: 5, r: 5 }),
                        borderRadius: 10,
                        "&:hover": {
                            backgroundColor: getColor()
                        }
                    }}
                    >
                        Submit
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}