
import { Box } from '@mui/material';

function Home() {


  return (
    <Box component="div" className="overview-features-area pb-70">
      <Box component="div" className="container">
        <Box component="div" className="row justify-content-center">
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className='overview-features-card'>
              <Box component="div" className="features-image">
                <img draggable={false}
                  src="/assets/img/ai-solution/features/features1.png"
                  alt="overview"
                />
              </Box>
              <Box component="div" className="features-content">
                <Box component="h3">Proof of Concepts</Box>
                <Box component="p">
                  In our pursuit of AI advancements, we specialize in crafting AI
                  proof of concepts that showcase the potential applications and
                  benefits across various domains. These prototypes serve as
                  tangible demonstrations, illustrating the transformative power
                  of artificial intelligence in solving real-world challenges.
                </Box>
                {/* <Link underline="none" to="#" class="features-btn">Learn More</Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="overview-features-card">
              <Box component="div" className="features-image">
                <img draggable={false}
                  src="/assets/img/ai-solution/features/features2.png"
                  alt="overview"
                />
              </Box>
              <Box component="div" className="features-content">
                <Box component="h3">R&amp;D for Viable Solution</Box>
                <Box component="p">
                  Engaged in relentless R&amp;D efforts, we are committed to
                  uncovering viable solutions that address complex challenges and
                  push the boundaries of innovation via AI. Our focus on research
                  &amp; development is driven by the pursuit of practical,
                  effective, and Innovative solutions for a diverse range of
                  Healthcare.
                </Box>
                {/* <Link underline="none" to="#" class="features-btn">Learn More</Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="overview-features-card">
              <Box component="div" className="features-image">
                <img draggable={false}
                  src="/assets/img/ai-solution/features/features3.png"
                  alt="overview"
                />
              </Box>
              <Box component="div" className="features-content">
                <Box component="h3">AI Network</Box>
                <Box component="p">
                  Our initiative focuses on enhancing collaborative connections
                  among AI Experts, fostering effective communication and shared
                  expertise. By cultivating a networked approach, Clients can
                  leverage collective strengths, facilitating seamless information
                  exchange and promoting synergistic productivity.
                </Box>
                {/* <Link underline="none" to="#" class="features-btn">Learn More</Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

  )
}

export default Home;