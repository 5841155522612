import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Grid } from '@mui/material';
import { sendEnquiryEmailOtpApi, sendEnquiryEmailApi } from 'src/apiUtils/apiPath';
import BannerForm from '../BannerForm/BannerForm';
import { validator } from 'src/validation/validation';
import { InputField, PhoneInput, TextArea, Snackbar } from 'src/components';
import { getColor, padding, commonButtonStyle } from 'src/palette';

const INPUT_BOX_COLOR = {
    backgroundColor: '#343a40',
    '& .MuiInputBase-input': {
        color: getColor('white')
    },
    '& .MuiOutlinedInput-input::placeholder': {
        color: getColor('white')
    },
}


EmailForm.propTypes = {
    userData: PropTypes.object,
    updateUser: PropTypes.func
};

export default function EmailForm({ userData, updateUser }) {

    const [type, setType] = useState('success')
    const [message, setMessage] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState(false)
    const [validateOtp, setValidateOtp] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [enquiryData, setEnquiryData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        otp: ''
    })

    const [checkLoginDataValid, setCheckLoginDataValid] = useState({
        name: [],
        email: [],
        phone: [],
        subject: [],
        message: [],
        otp: [],

    })

    useEffect(() => {
        const checkName = (userData?.firstName || userData?.lastName) ? `${userData?.firstName ? userData?.firstName : ''} ${userData?.lastName ? userData?.lastName : ''}` : '';
        // setEnquiryData((pre) => ({
        //     ...pre,
        //     email: userData?.email || pre.email ? (pre.email ?? userData?.email) : '',
        //     name: pre.name ? pre.name : checkName
        // }))
        console.log('enquiryData', enquiryData, userData);
        if (userData?.email) {
            setValidation(false)
        }
    }, [userData])

    useEffect(() => {
        setCheckLoginDataValid({
            name: validator({ value: enquiryData.name, validate: ['required'] }),
            message: validator({ value: enquiryData.message, validate: ['required'] }),
            subject: validator({ value: enquiryData.subject, validate: ['required'] }),
            email: validator({ value: enquiryData.email, validate: ['required', 'email'] }),
            phone: validator({ value: enquiryData.phone, validate: ['required', 'fixedLength'], length: 10 }),
            otp: validateOtp ? validator({ value: enquiryData.otp, validate: ['required', 'fixedLength'], length: 6 }) : [],
            enquiry_id: null
        })
    }, [enquiryData, validation])

    const getOtp = () => {

        setValidation(true)
        const formData = new FormData();
        const keys = Object.keys(enquiryData);
        console.log(keys)
        let checkValid = false
        keys.forEach((e) => {
            formData.append(e, enquiryData[e])
            if (checkLoginDataValid[e]?.length > 0 && e !== 'otp') {
                checkValid = true
            }
        })
        if (checkValid) {
            return
        }
        sendEnquiryEmailOtpApi(formData).then((result) => {
            if (result.status) {
                console.log(result)
                if (result.entity) {
                    setEnquiryData((pre) => ({
                        ...pre,
                        enquiry_id: result?.entity?.userDetailsId
                    }))
                }
                setMessage("OTP sent to your email address")
                setOpenSnackBar(true)
                setValidateOtp(true)
                setValidation(false)
            }
        })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }

    const submitEnquiry = () => {

        setValidation(true)
        const formData = new FormData();
        const keys = Object.keys(enquiryData);
        console.log(keys)
        let checkValid = false
        keys.forEach((e) => {
            formData.append(e, enquiryData[e])
            if (checkLoginDataValid[e]?.length > 0) {
                checkValid = true
            }
        })
        if (checkValid) {
            return
        }

        sendEnquiryEmailApi(formData).then((result) => {
            if (result.status) {
                console.log(result)
                setValidation(false)
                setMessage("Your Request has been submitted")
                setValidateOtp(false)
                setEnquiryData((pre) => ({
                    ...pre,
                    // email: userData?.email ? userData?.email : '',
                    // name: (userData?.firstName || userData?.lastName) ? `${userData?.firstName ? userData?.firstName : ''} ${userData?.lastName ? userData?.lastName : ''}` : '',
                    email: '',
                    name: '',
                    phone: '',
                    subject: '',
                    message: '',
                    otp: '',
                    enquiry_id: null
                }))
                setOpenSnackBar(true)
            } else {
                setType('error')
                setMessage(result.message)
                setOpenSnackBar(true)
            }
        })
            .catch(error => {
                // Handle error
                console.error(error);
            });


    }
    return (
        <>
            {openSnackBar && (<Snackbar
                onChange={() => {
                    setOpenSnackBar(false)
                    setType('success')
                }}
                msg={message}
                type={type}
            />)}
            < Box sx={{ marginBottom: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InputField
                            validate={validation}
                            disabled={validateOtp}
                            errorDetails={checkLoginDataValid?.name}
                            value={enquiryData.name}
                            placeholder='Enter Name'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    name: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InputField
                            validate={validation}
                            disabled={validateOtp}
                            errorDetails={checkLoginDataValid?.email}
                            value={enquiryData.email}
                            placeholder='Enter Email'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    email: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <PhoneInput
                            validate={validation}
                            disabled={validateOtp}
                            errorDetails={checkLoginDataValid?.phone}
                            value={enquiryData.phone}
                            placeholder='Enter Phone'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    phone: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InputField
                            validate={validation}
                            disabled={validateOtp}
                            errorDetails={checkLoginDataValid?.subject}
                            value={enquiryData.subject}
                            placeholder='Enter subject'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    subject: event
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextArea
                            validate={validation}
                            disabled={validateOtp}
                            errorDetails={checkLoginDataValid?.message}
                            value={enquiryData.message}
                            placeholder='Enter Message'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    message: event
                                }))
                            }}
                        />
                    </Grid>
                    {validateOtp ? <><Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <PhoneInput
                            validate={validation}
                            errorDetails={checkLoginDataValid?.otp}
                            value={enquiryData.otp}
                            placeholder='Enter OTP'
                            onChange={(event) => {
                                setEnquiryData((pre) => ({
                                    ...pre,
                                    otp: event
                                }))
                            }}
                            maxLength={6}
                        />
                    </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Button type="submit" variant="contained"
                                onClick={() => {
                                    getOtp()

                                }}
                                sx={{
                                    backgroundColor: getColor(),
                                    '&:hover': {
                                        backgroundColor: getColor(),
                                    },
                                    borderRadius: 20,
                                    padding: (2, 4, 4, 2),
                                    width: '100%'
                                }}
                            >
                                Resend Otp
                            </Button>
                        </Grid>
                    </>
                        : <></>}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            {validateOtp ? < Button type="submit" variant="contained"
                                onClick={() => {
                                    submitEnquiry()
                                    // getOtp()
                                }}
                                sx={{
                                    ...commonButtonStyle(),
                                    ...padding({ a: 2 })
                                }}
                            >
                                Validate & Submit
                            </Button>
                                :
                                <Button type="submit" variant="contained"
                                    onClick={() => {
                                        getOtp()

                                    }}
                                    sx={{
                                        ...commonButtonStyle(),
                                        ...padding({ a: 2 })
                                    }}
                                >
                                    Contact Us
                                </Button>
                            }
                            {/* : <BannerForm buttonName={'Login/Signup'} updateUser={() => {
                                updateUser()
                            }} navigateAdmin={false} /> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </>
    );

}