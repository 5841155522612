import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CssBaseline, Card } from '@mui/material';
import { Outlet } from "react-router-dom";
import SideBar from './SideBar';

const Main = styled('main')(
    ({ theme, drawerWidth }) => ({
        flexGrow: 1,
        marginLeft: `${drawerWidth}px`,
        height: '100vh',
        width: `calc(100% - ${drawerWidth}px)`,
        // padding: theme.spacing(1),
    }),
);

const AppContainer = styled(Card)(({ theme }) => ({
    boxShadow: '1px 1px 2px, 1px 1px 2px inset',
    width: '100%',
    minHeight: '100vh',
    overflow: 'auto',
    padding: theme.spacing(2),
    borderRadius: 4,
}));

export default function AdminLayout() {
    const [drawerWidth, setDrawerWidth] = useState(270);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <SideBar handleDrawerWidth={(value) => setDrawerWidth(value)} defaultRoute={'/admin'} />
            <Main drawerWidth={drawerWidth}>
                <AppContainer>
                    <Outlet />
                </AppContainer>
            </Main>
        </Box>
    );
}
