export const appApi = (url, method, headers = {"Content-Type": "application/json"}, body = '') =>
  new Promise(async (resolve, reject) => {
    const Token = localStorage.getItem("userToken")
    let options = {
      method,
      headers: {
        "authorization": `Bearer ${Token}`,
        "consumer_key": 'inolabai54321',
        ...headers,
      },
    };
    if (body) {
      options = { ...options, body, };
    }
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      if (json.status) {
        if (json.status === 'false') {
          reject(json.reason);
        } else {
          resolve(json);
        }
      } else {
        resolve(json);
      }
    } catch (error) {
      reject(error);
    }

  })
