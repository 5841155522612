import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { Button, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {getColor} from 'src/palette';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap', // Allow items to wrap to next line on smaller screens
});

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  rowPerPage: PropTypes.number
};

export default function Pagination({ onPageChange, count = 0, rowPerPage = 10 }) {

  const getCount = (value, row) => {
    if (parseInt(value) === 0) return 0;
    return Math.ceil(value / row);
  }

  useEffect(() => {
    getCount(count, rowPerPage);
  }, [count, rowPerPage]);

  const totalPages = getCount(count, rowPerPage);

  const { items } = usePagination({
    count: totalPages,
    onChange: (event, page) => onPageChange(page),
  });

  return (
    <>
      {totalPages > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <nav>
            <List>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;
                let buttonStyle = {};

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = '…';
                } else if (type === 'page') {
                  buttonStyle = {
                    fontWeight: selected ? 'bold' : undefined,
                    color: selected ? getColor() : 'white',
                    margin: '5px', // Add some spacing between buttons
                  };
                  children = (
                    <Button
                      type="button"
                      style={buttonStyle}
                      {...item}
                    >
                      {page}
                    </Button>
                  );
                } else if (type === 'previous') {
                  children = (
                    <IconButton type="button" {...item} sx={{ color: 'white', margin: '5px' }}>
                      <ArrowBackIosIcon />
                    </IconButton>
                  );
                } else if (type === 'next') {
                  children = (
                    <IconButton type="button" {...item} sx={{ color: 'white', margin: '5px' }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  );
                }

                return <li key={index}>{children}</li>;
              })}
            </List>
          </nav>
        </Box>
      )}
    </>
  );
}
