
import { Box } from '@mui/material';
import CountUp from "react-countup";
import Carousel from 'react-grid-carousel';

const COUNT_DATA = [
  { name: 'Completed Poc/Research', end: 3 },
  { name: 'Satisfied Clients', end: 4 },
  { name: 'Industries Served', end: 2 },
  { name: 'Countries Served', end: 2 },
]

function Home() {


  return (
    <Box component="div" className="counter-area pb-100" id="counterSection">
      <Box component="div" className="container">
        <Box component="div" className="counter-inner-box pt-100 pb-70">
          <Box component="div" className="row justify-content-center">
            {COUNT_DATA.map((list) => (
              <Box component="div" className="col-lg-3 col-sm-6">
                <Box component="div" className="counter-card">
                  <Box component="div" className="icon">
                    <i className="bx bx-check-shield"></i>
                  </Box>
                  <Box component="h3">
                    <CountUp start={0} end={list.end} duration={10} separator={""} />
                    <Box component="span" className="sign-icon">+</Box>
                  </Box>
                  <Box component="p">{list.name}</Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box component="div" className="counter-shape">
            <img draggable={false} src="/assets/img/ai-solution/counter-shape.png" alt="image" />
          </Box>
        </Box>
      </Box>
    </Box>

  )
}

export default Home;