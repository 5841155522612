import { TableCell, TableRow, Box, Grid, Button, Typography, TablePagination } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import { SearchField, TableComponent } from 'src/components'
import { getColor, padding, margin, headingColor } from 'src/palette';
import FormModal from './FormModal';
import { getUserEnquiryListApi } from 'src/apiUtils/apiPath';
import moment from 'moment-timezone';



const TABLE_HEAD = [
    { id: 'id', label: 'id', alignHeader: false },
    { id: 'name', label: 'Name', alignHeader: false },
    { id: 'message', label: 'Message', alignHeader: false },
    { id: 'email', label: 'Email', alignHeader: false },
    { id: 'phone', label: 'Phone Number', alignHeader: false },
    { id: 'date', label: 'Date', alignHeader: false },
];

export default function BasicComponent() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [orderBy, setOrderBy] = useState('user.id');
    const [openModal, setOpenModal] = useState(false)
    const [tableData, setTableData] = useState([])
    const [tableDataCount, setTableDataCount] = useState(0)

    const handleTableData = (page, value) => {
        console.log(page, value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = async (event) => {
        console.log(event.target.value);
        setRowsPerPage(event.target.value);
    };

    useEffect(() => {
        getUserEnquiryList()
    }, [page, rowsPerPage, searchValue]);
    const getUserEnquiryList = () => {
        const param = {
            page: page + 1,
            limit: rowsPerPage,
            search: searchValue
        }
        getUserEnquiryListApi(param).then((result) => {
            if (result.status) {
                const responseData = result.entity;
                setTableData(responseData.blogList);
                setTableDataCount(responseData.blogCount ?? 0)
            }
            console.log(tableData)
        })
            .catch(error => {
                // Handle error
                console.error(error);
            });
        // .finally(() => setLoading(false));
    }


    const openLoginModal = () => {
        if (openModal === true) {
            setOpenModal(false)
        } else {
            setOpenModal(true)
        }
    }
    return (
        <Fragment>
            <Box sx={{ ...padding({ a: 2 }), ...margin({ b: 2 }), borderRadius: 2, display: 'flex' }}>
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item>
                        <Button sx={{
                            color: getColor('white'),
                            backgroundColor: getColor(),
                            ...padding({ a: 2, l: 5, r: 5 }),
                            borderRadius: 10,
                            "&:hover": {
                                backgroundColor: getColor()
                            }
                        }}
                            onClick={() => openLoginModal()}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item>
                        <SearchField onChange={(value) => {
                            setSearchValue(value)
                            setPage(0)
                        }} />
                    </Grid>
                </Grid>
            </Box>
            <TableComponent
                headerList={TABLE_HEAD}
                count={tableDataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
                {tableData.map((list) => (
                    <TableRow key={list._id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.id ?? '--'}</TableCell>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.name ?? '--'}</TableCell>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.message ?? '--'}</TableCell>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.email ?? '--'}</TableCell>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.phone ?? '--'}</TableCell>
                        <TableCell align="left" sx={{ ...padding({ a: 3 }) }}>{list?.createdAt ? moment(list.createdAt).format('DD-MM-YYYY') : '--'}</TableCell>
                    </TableRow >
                ))
                }
            </TableComponent >
            <FormModal
                openModal={openModal}
                handleModel={() => {
                    openLoginModal()
                }}
            />
        </Fragment >
    )
}