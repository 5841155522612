import { TableCell, TableRow, Box, Grid, Button, Typography, TablePagination, Divider } from '@mui/material';
import { Fragment, useState } from 'react';
import { SearchField, TableComponent } from 'src/components'
import { getColor, padding, margin, headingColor } from 'src/palette';
import TableList from './TableList';


export default function BasicComponent() {
    return (
        <Fragment>
            <Box sx={{ ...padding({ a: 2 }) }}>
                <Typography variant='h4' sx={{ WebkitTextFillColor: '#3C0753' }}>User Enquiry</Typography>
            </Box>
            <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
            <Box sx={{ ...margin({ t: 4 }) }}>
                <TableList />
            </Box>
        </Fragment>
    )
}