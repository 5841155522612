import {
  Testimonial, AboutSection, ServiceSection, FooterSection, BlogSection, TeamSection, ContactSection, OverviewSection, BlogSubscriptionSection, CounterSection, HeaderSection, SolutionSection, BannerSection
} from "./SubPages"
import { Box } from '@mui/material';
import { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { ScrollToTopButton } from 'src/components'

export default function Home() {
  const [userData, setUserData] = useState(null)
  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
    updateUser()
  }, [])
  const updateUser = () => {
    const getLocalData = localStorage.getItem('userLoginDetails') ? localStorage.getItem('userLoginDetails') : ''
    const localUserData = getLocalData ? JSON.parse(getLocalData) : {}
    // console.log(getLocalData);
    setUserData(localUserData)
  }

  return (
    <Box component="div" id="home">
      <HeaderSection userData={userData} updateUser={() => {
        updateUser()
      }} />
      <BannerSection userData={userData} />
      <ScrollToTopButton />
      <OverviewSection userData={userData} />
      <AboutSection userData={userData} />
      <ServiceSection userData={userData} />
      <SolutionSection userData={userData} />
      <CounterSection userData={userData} />
      <TeamSection userData={userData} />
      {/* <Testimonial userData={userData} /> */}
      {/* <BlogSection userData={userData} /> */}
      <ContactSection userData={userData} updateUser={() => {
        updateUser()
      }} />
      {/* <BlogSubscriptionSection userData={userData} /> */}
      <FooterSection userData={userData} />
      <ToastContainer userData={userData} />
    </Box >

  )
}