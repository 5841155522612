import { getColor, headingColor, screenSize, padding } from 'src/palette';

const style = {
    modalStyle: {
        position: 'absolute',
        top: screenSize('28%', '28%', '50%', '50%', '50%'),
        left: screenSize('25%', '25%', '50%', '50%', '50%'),
        width: screenSize('95%', '95%', '40%', '40%', '40%'),
        // height: screenSize('80%', '70%', '80%', '80%', '80%'),
        transform: screenSize('translate(-25%, -25%)', 'translate(-25%, -25%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)', 'translate(-50%, -50%)'),
        bgcolor: getColor('white'),
        boxShadow: 24,
        borderRadius: '20px',
        overflow: 'auto',
        ...padding({ a: 2 })
    },
}


export default style;