
export function screenSize(xs, sm, md, lg, xl) {
    return { xs, sm, md, lg, xl }
}
export function getColor(color = 'primary') {
    const colorList = {
        primary: '#ffac00',
        white: '#ffffff',
        black0: '#000000',
        glassColor: 'rgba(0,0,0,0.5)',
        buttoncolor: 'linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)',
        error: 'error',
        // admin_primary : '#191c24', 
        admin_primary: '#313B52',
        admin_head: '#3C0753',
        input_background : '#343a40'

    }

    return colorList[color ?? 'primary'];
}

export function padding({ a = 0, l = 0, r = 0, t = 0, b = 0 }) {
    const padding = {}
    padding.paddingTop = t ? t : a;
    padding.paddingLeft = l ? l : a;
    padding.paddingRight = r ? r : a;
    padding.paddingBottom = b ? b : a;
    return padding
}

export function margin({ a = 0, l = 0, r = 0, t = 0, b = 0 }) {
    const margin = {}
    margin.marginTop = t ? t : a;;
    margin.marginLeft = l ? l : a;;
    margin.marginRight = r ? r : a;;
    margin.marginBottom = b ? b : a;;
    return margin
}

export function displayItem({ d = '', jc = '', ai = '', ji = '', ac = '' }) {
    const alignment = {}
    if (d) {
        alignment.display = d
    }
    if (jc) {
        alignment.justifyContent = jc
    }
    if (ac) {
        alignment.alignContent = ac
    }
    if (ji) {
        alignment.justifyItems = ji
    }
    if (ai) {
        alignment.alignItems = ai
    }
    return alignment
}

export function size(h = '100%', w = '100%') {
    return { height: h, width: w }
}

export function border(size, color = '') {
    return { border: `${size}px solid ${getColor(color ? color : 'primary')}` }
}

export function transition(size, time) {
    return {
        WebkitTransform: `translateY(${size}px)`,
        transform: `translateY(${size}px)`,
        transition: `${time}s`
    }
}

export function headingColor() {
    return {
        color: '#B4EEFF',
        backgroundImage: '-webkit-linear-gradient(1deg, #9D72FE -5%, #2FB1C7 60%, #6796FF 80%, #7ECDFF 110%)',
        backgroundSize: '200% 200%',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        animation: 'gradientAnimation 6s infinite alternate'
    }
}

export function commonButtonStyle() {
    return {
        background: 'linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)',
        padding: 1, "&:hover": {
            background: "linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)"
        },
        color: 'white',
        fontWeight: 700,
        borderRadius: 20,
        paddingLeft: 2,
        paddingRight: 2,
        textTransform: 'none'
    }
}