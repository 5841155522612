import { Box, Typography, Button, IconButton } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { InputField } from "src/components/ChatComponent";
import { Show } from "src/Helpers/Show";
import { config } from 'src/config/config';
import { appApi } from "src/apiUtils/AppApi";
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { getColor } from "src/palette";
const chatApiUrl = config.chatApiUrl;

export default function ChatForm() {
    const [chat, setChat] = useState('');
    const [chatAnswer, setChatAnswer] = useState('');
    const [loading, setLoading] = useState(true)
    const chatTextBoxRef = useRef(null);

    const generateRandomString = (length) => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset[randomIndex];
        }
        return result;
    }

    const generateSessionId = () => {
        const timestamp = new Date().getTime(); // Get current timestamp
        const randomString = generateRandomString(6); // Generate a random string of length 6
        const randomPart = Math.random().toString(36).substring(2, 8); // Additional random string
        return `${timestamp}-${randomPart}-${randomString}`;
    }

    const getAnswer = async () => {
        try {
            if (loading) {
                const sessionId = generateSessionId(); // Generate session ID
                console.log(sessionId); // Output session ID
                localStorage.setItem("chat_session_id", sessionId)
                setChatAnswer("")
                setLoading(false)
                // const data = { "prompt": chat };
                const data = { "prompt": chat, "sessionId": sessionId };
                // const data = { "prompt": "Generate A diet plan for one week" };
                const Token = localStorage.getItem("userToken");
                fetch(`${chatApiUrl}stream-chat`, {
                    method: 'POST',
                    headers: {
                        "authorization": `Bearer ${Token}`,
                        "consumer_key": 'inolabai54321',
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const reader = response.body.getReader();

                        const answerData = []
                        function readStream() {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    console.log('Stream finished');
                                    setLoading(true)
                                    return;
                                }
                                const chunk = new TextDecoder().decode(value);
                                if (chunk !== undefined && chunk !== null) {
                                    answerData.push(chunk)
                                    setChatAnswer(answerData.join(' '));
                                    console.log('Received chunk:', answerData.join(' '));
                                }
                                readStream();
                            }).catch(error => {
                                console.error('Error reading stream:', error);
                            });
                        }
                        readStream();
                        return;
                    })
                    .then(data => {
                        console.log(data);
                        // Continue processing the response data as needed
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    })


                // Continue with other tasks concurrently without waiting for the streaming to complete
                console.log('Continuing with other tasks...');
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // Scroll to the bottom of the chat text box when chatAnswer changes
        chatTextBoxRef.current.scrollTop = chatTextBoxRef.current.scrollHeight;
    }, [chatAnswer]);

    const stopStreaming = () => {
        const sessionId = localStorage.getItem("chat_session_id")
        if (!loading) {
            appApi(`${chatApiUrl}stop-streaming`, 'POST', { "Content-Type": "application/json" }, JSON.stringify({ sessionId }))
        }
    }

    return (
        <Box className="chat-form">
            <Box className="chat-input">
                <InputField
                    value={chat}
                    placeholder='Message Inolabs...'
                    onChange={(event) => {
                        setChat(event);
                    }}
                    onSubmit={getAnswer}
                />
                <Button onClick={getAnswer} className="chat-button" disabled={chat === "" || chat === null || chat === undefined || !loading}>
                    Send
                </Button>
            </Box>
            {/* <Show.When isTrue={chatAnswer}> */}
            <Box className="chat-text-copy">
                Response
                <IconButton onClick={() => { stopStreaming() }} className="chat-IconButton" disabled={loading}>
                    <StopCircleOutlinedIcon sx={{ color: getColor('white'), opacity: loading ? 0.6 : 1 }} />
                </IconButton>
            </Box>
            <Box className="chat-text" ref={chatTextBoxRef}>
                <Box className="chat-text-response">
                    {chatAnswer.split('\n').map((text, index) => (
                        <Typography key={index}>{text}</Typography>
                    ))}
                </Box>
            </Box>
            {/* </Show.When> */}
        </Box>
    );
}