import { Box, Button, Stack, TextField, Typography, InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { QuilEditor, FileUpload } from 'src/components'
import { appApi } from 'src/apiUtils/AppApi';
import { blogUpdateCreateApi } from 'src/apiUtils/apiPath';
import PropTypes from 'prop-types';
import { config } from 'src/config/config'
import { getColor } from 'src/palette';

const serverImage = config.serverImage

const INPUT_STYLE = {
    backgroundColor: '#343a40',
    color: '#20c997',
    '& .MuiOutlinedInput-input::placeholder': {
        color: getColor('white'),
        fontWeight: 500
    },
    '& .MuiInputBase-input': {
        color: getColor('white')
    },
}


const BUTTON_STYLE = {
    background: 'linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)',
    padding: 1, "&:hover": {
        background: "linear-gradient(231.69deg, #C255BE 13.24%, #485BBC 97.21%)"
    },
    color: 'white',
    fontWeight: 700,
    borderRadius: 20,
    paddingLeft: 2,
    paddingRight: 2
}
BlogEdit.propTypes = {
    blogEditData: PropTypes.object,
    formType: PropTypes.string,
    handleBlogEdit: PropTypes.func
};

export default function BlogEdit({ blogEditData, formType, handleBlogEdit }) {
    console.log('testoijadklasdfjalsk ', blogEditData)

    const [blogData, setBlogData] = useState({});
    const [userData, setUserData] = useState(null)
    useEffect(() => {
        updateUser()
        console.log('blogEditData', blogEditData, formType)
        setBlogData({
            title: formType === 'edit' && blogEditData?.blog_title ? blogEditData.blog_title : '',
            updatedContent: formType === 'edit' && blogEditData?.altered_blog ? blogEditData.altered_blog : '',
            image: formType === 'edit' && blogEditData?.blog_picture ? blogEditData.blog_picture : '',
            originalContent: formType === 'edit' && blogEditData?.original_blog ? blogEditData.original_blog : '',
            blogId: formType === 'edit' && blogEditData?.id ? blogEditData.id : '',
            blogTag: formType === 'edit' && blogEditData?.blog_tag ? blogEditData.blog_tag : '',
            imagePath: formType === 'edit' && blogEditData?.blog_picture ? blogEditData.blog_picture : '',
        })

    }, [blogEditData])
    const updateUser = () => {
        const getLocalData = localStorage.getItem('userLoginDetails') ? localStorage.getItem('userLoginDetails') : ''
        const localUserData = getLocalData ? JSON.parse(getLocalData) : {}
        // console.log(getLocalData);
        setUserData(localUserData)
    }


    const saveBlogData = (type) => {
        const formData = new FormData();
        const keys = Object.keys(blogData);

        keys.forEach((e) => {
            formData.append(e, blogData[e])
        })
        formData.append('type', type)
        console.log('userData ====>>> ', userData)
        formData.append('user_id', userData.id)
        blogUpdateCreateApi(formData).then((result) => {
            if (result.status) {
                const responseData = result.entity;
                setBlogData((pre) => ({
                    ...pre,
                    blogId: responseData.id
                }))
            }
            console.log(blogData)
        })
            .catch(error => {
                // Handle error
                console.error(error);
            });
    }
    return (
        <Box sx={{ padding: 2, height: '75vh' }}>

            <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
                <Button
                    color="warning"
                    variant="contained"
                    onClick={() => {
                        saveBlogData('save')
                    }}
                >
                    Save
                </Button>

                <Button
                    variant="contained"
                    onClick={() => {
                        saveBlogData('publish')
                    }}
                >
                    Publish
                </Button>
                <Button
                    variant="contained"
                    color="error"
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => { handleBlogEdit('') }}
                >
                    Back
                </Button>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}>
                <Stack
                    direction={'column'}
                    spacing={4}
                    sx={{
                        padding: 1,
                        width: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }
                    }}
                >
                    <Box sx={{ width: '100%', marginBottom: 2, height: '100%', }}>
                        <Typography variant="label" color={'white'} sx={{ margin: 1 }}>
                            Blog Title
                        </Typography>
                        <TextField
                            fullWidth
                            sx={{
                                ...INPUT_STYLE,

                                '&:hover': {
                                    border: '1px solid #000000'
                                },
                                border: '1px solid #000000',
                                borderRadius: '10px'
                            }}
                            value={blogData.title}
                            multiline
                            rows={6}
                            onChange={(event) => {
                                setBlogData((pre) => ({
                                    ...pre,
                                    title: event.target.value
                                }))
                            }}
                        />
                    </Box>

                    <Box sx={{ width: '100%', margin: 2, height: '100%', }}>
                        <Typography variant="label" color={'white'} sx={{ margin: 1 }}>
                            Blog Tag
                        </Typography>
                        <TextField
                            fullWidth
                            sx={{
                                ...INPUT_STYLE,

                                '&:hover': {
                                    border: '1px solid #000000'
                                },
                                border: '1px solid #000000',
                                borderRadius: '10px'
                            }}
                            value={blogData.blogTag}
                            multiline
                            rows={8.5}
                            onChange={(event) => {
                                setBlogData((pre) => ({
                                    ...pre,
                                    blogTag: event.target.value
                                }))
                            }}
                        />
                    </Box>
                    <Box sx={{
                        width: '100%',
                        margin: 2
                    }}>
                        <Typography variant="label" color={'white'} sx={{ margin: 1 }}>
                            Blog Image
                        </Typography>
                        <FileUpload
                            others={{ ...INPUT_STYLE }}
                            title={'Upload'}
                            inputValue={`${blogData.imagePath ? serverImage + blogData.imagePath : ''}`}
                            updateFileValue={(value) => {
                                setBlogData((pre) => ({
                                    ...pre,
                                    image: value
                                }))
                            }}
                        />
                    </Box>
                </Stack >
                <QuilEditor
                    inputValue={blogData.updatedContent}
                    updateInputValue={(value) => {
                        console.log('testing', value)
                        setBlogData((pre) => ({
                            ...pre,
                            updatedContent: value
                        }))

                    }}
                />

            </Stack>


            {/* <HtmlConverter htmlData={} /> */}
        </Box >
    )
}