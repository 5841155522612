import React, { useState, useEffect } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { commonButtonStyle, getColor } from 'src/palette';

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Function to scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Function to toggle visibility of the button based on scroll position
    const toggleVisibility = () => {
        if (window.scrollY > 300) { // Adjust the scroll position as needed
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Add event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <Tooltip title="Scroll To Top">
                    <IconButton
                        className="scrollToTopButton"
                        sx={{
                            color: getColor('white'), backgroundColor: getColor(), '&:hover': { backgroundColor: getColor() }, position: 'fixed', bottom: '20px', zIndex: 9999,
                            right: '20px'
                        }}
                        variant="contained"
                        onClick={scrollToTop}
                    >
                        <KeyboardArrowUpIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
}

export default ScrollToTopButton;
