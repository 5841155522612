import React from 'react';

export default function HtmlConverter({ htmlData }) {
    //   constructor(props) {
    //     super(props);
    //     this.state = {
    //       htmlContent: '<p>This is some <strong>HTML</strong> content.</p>'
    //     };
    //   }
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        </div>
    );
}
