import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    TextField,
    Chip,
    Collapse,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CustomTableHead from './CustomTableHead'
import { getColor } from 'src/palette';
import { Show } from 'src/Helpers/Show';
TableList.propTypes = {
    headerList: PropTypes.array,
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,

};

export default function TableList({ children,
    headerList = [],
    count,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage, }) {

    return (
        <Box sx={{
            boxShadow: '1px 1px 2px, 1px 1px 2px inset',
            overflow: 'hidden',
            borderRadius: 4,
        }}>
            <TableContainer aria-label="collapsible table" component={Paper} sx={{
                maxHeight: '70vh',
                '&::-webkit-scrollbar': { display: 'block', width: '3px' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
                '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
                borderColor: getColor('admin_primary')
            }}>
                <Table stickyHeader>
                    <CustomTableHead
                        headLabel={headerList}
                        onRequestSort={(event, property) => console.log('testing', event, property)}
                    />
                    <TableBody>
                        {children}
                    </TableBody>
                    <Show.When isTrue={parseInt(count) === 0}>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <Typography variant="h6" sx={{ WebkitTextFillColor: 'red' }} color={'red'}>
                                        No Data Found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Show.When>
                </Table>
            </TableContainer>
            <Card>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage=""
                />
            </Card>
        </Box >
    )
}