import { Box, Link } from '@mui/material';
import { headingColor } from 'src/palette'


function Home() {
  return (
    <Box component="div" id="service" className="ai-services-area ptb-100"  onMouseDown={(e) => e.preventDefault()} >
      <Box component="div" className="container">
        <Box component="div" className="section-title">
          <Box component="h2">
            See Wide Range Of Our <Box component="b" sx={headingColor()}>Services</Box> With Artificial Intelligence In Healthcare
          </Box>
          {/* <Box component="p">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna
            aliqua.
          </Box> */}
        </Box>
        <Box component="div" className="row justify-content-center" id="service-box">
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-1.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">Robotics And Drones</Link>
                </Box>
                <Box component="p">
                  Robotics and drones in AI healthcare revolutionize patient care by delivering remote diagnostics and treatment, enhancing precision in surgeries, and ensuring timely delivery of medical supplies. Their integration promises a future where healthcare is more accessible, efficient, and personalized.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-2.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">Machine Learning</Link>
                </Box>
                <Box component="p">
                  Machine Learning in AI healthcare enables predictive analytics for disease diagnosis, personalized treatment plans, and the identification of patterns in patient data for proactive healthcare interventions. It empowers medical professionals with data-driven insights, fostering a more efficient and tailored approach to patient care.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-3.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">Image Processing</Link>
                </Box>
                <Box component="p">
                  Image processing in AI healthcare transforms diagnostic accuracy by enabling advanced analysis of medical images, such as X-rays and MRIs, for early detection of diseases like cancer. It leverages deep learning algorithms to enhance image clarity, interpret complex patterns, and provide precise, life-saving diagnoses.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-4.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">Virtual Reality</Link>
                </Box>
                <Box component="p">
                  Virtual Reality (VR) in AI healthcare offers immersive experiences for patient therapy, surgical training, and the visualization of complex medical conditions, enhancing both patient outcomes and medical education. It bridges gaps in physical and psychological treatments, enabling innovative approaches to rehabilitation and surgical preparation.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-5.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">AI For Cloud Services</Link>
                </Box>
                <Box component="p">
                  AI for Cloud Services in healthcare leverages scalable computing power to analyze vast datasets, improving disease prediction, treatment personalization, and operational efficiencies. It facilitates seamless integration and collaboration across platforms, ensuring secure, real-time access to patient data and analytics for informed decision-making.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-sm-6">
            <Box component="div" className="ai-services-card">
              <Box component="div" className="services-image">
                <img draggable={false}
                  src="/assets/img/artificial-intelligence/services/services-6.png"
                  alt="service-image"
                />
              </Box>
              <Box component="div" className="services-content">
                <Box component="h3">
                  <Link underline="none" to="#">Deep Learning</Link>
                </Box>
                <Box component="p">
                  Deep Learning in AI healthcare drives breakthroughs in predictive analytics, image recognition, and patient data analysis, enabling earlier disease detection and more personalized treatment options. By mimicking the human brain's ability to learn, it uncovers hidden patterns in complex medical data, revolutionizing diagnostics and therapeutic strategies.
                </Box>
                {/* <Link underline="none" to="#" className="services-btn">
                  Read More
                </Link> */}
              </Box>
            </Box>
          </Box>
          {/* <Box component="div" className="col-lg-12 col-md-12">
            <Box component="div" className="view-all-services-btn">
              <Button sx={BUTTON_STYLE}>
                View All Services
              </Button>
            </Box>
          </Box> */}
        </Box>
      </Box>
      <Box component="div" className="ai-services-shape">
        <img draggable={false}
          src="/assets/img/artificial-intelligence/services/shape.png"
          alt="service-image"
        />
      </Box>
    </Box>

  )
}

export default Home;