import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

CustomSnackBar.propTypes = {
    msg: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string
};



export default function CustomSnackBar({ onChange, msg, type = "success" }) {
    useEffect(() => {
        setTimeout(() => {
            onChange()
        }, 2000)
    }, [])

    return (
        <div>
            <Snackbar
                open={true}
                autoHideDuration={2000}
                onClose={() => {
                    onChange()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    onClose={() => onChange()}
                    severity={type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    );
}