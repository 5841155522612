import { Box, Typography, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getColor, padding } from 'src/palette';



InputError.propTypes = {
    errorDetails: PropTypes.array,
    show: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    rows: PropTypes.number
};
export default function InputError(
    {
        errorDetails,
        validate,
        onChange,
        value,
        disabled,
        placeholder,
        rows = 4
    }
) {



    const [isValid, setIsValid] = useState(true)
    const [msg, setMsg] = useState('')
    const [showValidation, setShowValidation] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const setMessages = () => {
        const msg = errorDetails?.length > 0 ? errorDetails[0]?.msg : '';
        const validData = errorDetails[0]?.notValid ?? false;
        setMsg(msg)
        setIsValid(validData)
        setShowValidation(validate)
        setIsDisabled(disabled)

    }

    useEffect(() => {
        setMessages()
    }, [errorDetails, validate])
    const INPUT_BOX_COLOR = {
        '& .MuiOutlinedInput-root': {
            borderRadius: 6,
            ...padding({ a: 2 }),
            border: `1px solid ${getColor('input_background')}`,
            '& fieldset': {
                borderColor: getColor('input_background'),
                borderRadius: 6, 
            },
            '&:hover fieldset': {
                borderColor: getColor('input_background'),
            },
            '&.Mui-focused fieldset': {
                borderColor: getColor('input_background'),
            },
        },
        '& .MuiInputBase-input': {
            color: getColor('white'),
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('white')
        },
        backgroundColor: '#343a40',
        opacity: isDisabled ? 0.6 : 1,
        borderRadius: 6
    };


    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <TextField
                error={isValid && showValidation}
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                }}
                fullWidth
                placeholder={placeholder}
                name="Email"
                sx={INPUT_BOX_COLOR}
                multiline
                rows={rows}
                InputProps={{
                    readOnly: isDisabled,
                }}
                autoComplete={'off'}
            />
        </Box>
        <Box sx={{ height: '10px', padding: 1 }}>
            {isValid && showValidation ? <Typography variant="subtitle" sx={{ color: 'red' }}>{msg}</Typography> : <></>}
        </Box>
    </>
    );
}
