import { Box, Typography, IconButton, Stack } from "@mui/material";
import { HtmlConverter } from 'src/components';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Edit as EditIcon, Add as AddIcon, Menu as MenuIcon } from "@mui/icons-material";
import BlogList from './BlogList'
import {getColor} from 'src/palette';

import { config } from 'src/config/config'

const serverImage = config.serverImage

BlogView.propTypes = {
    // selectedBlog: PropTypes.object,
    handleBlogEdit: PropTypes.func
};
export default function BlogView({
    // selectedBlog,
    handleBlogEdit
}) {

    const [htmlData, setHtmlData] = useState('')
    const [selectedBlog, setSelectedBlog] = useState({})
    const [Disable, setDisable] = useState(true)

    useEffect(() => {
        setHtmlData(selectedBlog?.content)
        console.log(htmlData)
    }, [selectedBlog])
    return (
        <>
            <Box sx={{ height: '100%', padding: 3 }}>
                {/* <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Making Peace With The Feast Or Famine Of Freelancing</h2>
                    </div>
                </div>
            </div> */}
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <IconButton onClick={() => { handleBlogEdit('add', {}) }}>
                        <AddIcon sx={{ color: getColor('white') }} />
                    </IconButton>
                    <IconButton onClick={() => { handleBlogEdit('edit', selectedBlog) }} disabled={Disable} sx={{ opacity: Disable ? .5 : 1 }}>
                        <EditIcon sx={{ color: getColor('white') }} />
                    </IconButton>
                    <BlogList
                        openSelectedBlog={(value) => {
                            setSelectedBlog(value)
                            console.log(value)
                        }}
                        getCount={(value) => {
                            value > 0 ? setDisable(false) : setDisable(true)
                        }
                        }
                    />
                </Stack>
                {Disable ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="h3" sx={{ color: 'red' }}>No Blog Found</Typography>
                    </Box > :
                    <>
                        <Box sx={{
                            backgroundColor: '#0ca59d',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 5
                        }}>
                            <Typography variant="h4" color={getColor('white')} sx={{
                                fontFamily: "Dosis",
                                // , sans-serif
                                fontWeight: 700
                            }}>{selectedBlog.blog_title}</Typography>
                        </Box>
                        <Box sx={{
                            marginTop: 2,
                            marginBottom: 2,

                        }}>
                            <img draggable={false} src={`${serverImage + selectedBlog.blog_picture}`} alt="blogimage" width={'100%'} />
                        </Box>
                        <section className="blog-details-area ptb-110">
                            <HtmlConverter htmlData={htmlData} />
                        </section>
                    </>
                }

            </Box>
        </>
    )
}