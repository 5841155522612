import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { getColor, padding } from 'src/palette';

Search.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

export default function Search({
    onChange,
    value,
    disabled,
    placeholder
}) {
    const [isDisabled, setIsDisabled] = useState(false);

    const INPUT_BOX_COLOR = {
        '& .MuiInputBase-input': {
            color: getColor('admin_primary'),
            border: `1px solid ${getColor('admin_primary')}`,
            borderRadius: 8,
            ...padding({ l: 4, r: 4, a: 2 })
        },
        '& .MuiOutlinedInput-root': {
            border: `1px solid ${getColor('admin_primary')}`,
            borderRadius: 8,
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('admin_primary')
        },
        // '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        //     borderColor: getColor('admin_primary') // Change border color on hover
        // },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: getColor('admin_primary') // Change border color on focus
        },
        opacity: isDisabled ? 0.6 : 1,
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TextField
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                }}
                fullWidth
                placeholder={`🔎 ${placeholder || 'Search...'}`}
                sx={INPUT_BOX_COLOR}
                InputProps={{
                    readOnly: isDisabled,
                }}
                variant="outlined"
                color='primary'
                type="search"
            />
        </Box>
    );
}
