import { TableCell, TableRow, Box, Grid, Button, Typography, TablePagination } from '@mui/material';
import { Fragment, useState } from 'react';
import { SearchField, TableComponent } from 'src/components'
import { getColor, padding, margin, headingColor } from 'src/palette';
import FormModal from './FormModal';



const TABLE_HEAD = [
    { id: 'user', label: 'User Name', alignHeader: false },
    { id: 'date', label: 'Date', alignHeader: false },
    { id: 'slot', label: 'Slot Time', alignHeader: false },
    { id: 'status', label: 'Status', alignHeader: false },
    { id: 'actions', label: 'Actions', alignHeader: false },
];

const tableData = [
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
    { user: 'testing', date: 'testing', slot: 'testing', status: 'testing', actions: 'testing' },
]

export default function BasicComponent() {
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [count, setCount] = useState(5);

    const [orderBy, setOrderBy] = useState('user.id');
    const [openModal, setOpenModal] = useState(false)

    const handleTableData = (page, value) => {
        console.log(page, value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleTableData(newPage, rowsPerPage)
    };
    const handleChangeRowsPerPage = async (event) => {
        console.log(event.target.value);
        setRowsPerPage(event.target.value);
        handleTableData(page, event.target.value)
    };

    const openLoginModal = () => {
        if (openModal === true) {
            setOpenModal(false)
        } else {
            setOpenModal(true)
        }
    }
    return (
        <Fragment>
            <Box sx={{ ...padding({ a: 2 }), ...margin({ b: 2 }), borderRadius: 2, display: 'flex' }}>
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item>
                        <Button sx={{
                            color: getColor('white'),
                            backgroundColor: getColor(),
                            ...padding({ a: 2, l: 5, r: 5 }),
                            borderRadius: 10,
                            "&:hover": {
                                backgroundColor: getColor()
                            }
                        }}
                            onClick={() => openLoginModal()}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item>
                        <SearchField onChange={(value) => console.log(value)} />
                    </Grid>
                </Grid>
            </Box>
            <TableComponent
                headerList={TABLE_HEAD}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            >
                {tableData.map((list) => (
                    <TableRow key={list._id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell align="left">{list?.user ?? '--'}</TableCell>
                        <TableCell align="left">{list?.date ?? '--'}</TableCell>
                        <TableCell align="left">{list?.slot ?? '--'}</TableCell>
                        <TableCell align="left">{list?.status ?? '--'}</TableCell>
                        <TableCell align="left">{list?.actions ?? '--'}</TableCell>
                    </TableRow>
                ))}
            </TableComponent>
            <FormModal
                openModal={openModal}
                handleModel={() => {
                    openLoginModal()
                }}
            />
        </Fragment>
    )
}