import { useEffect, useState } from 'react';

import BannerForm from "../BannerForm/BannerForm"
import BannerContactModal from "../BannerContactModal/BannerContactModal"

import { Avatar, Box, IconButton, AppBar, Drawer, Container, Toolbar, Typography, Link, Button, ListItem, List } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { padding } from 'src/palette';
import { commonButtonStyle, headingColor } from 'src/palette'


function Home() {
  const navigate = useNavigate()
  const [isLogin, setIsLogin] = useState(false);
  const [loginUserData, setLoginUserData] = useState({});
  useEffect(() => {
    const getLocalData = localStorage.getItem('jumptoelement')
    console.log(getLocalData)
    if (getLocalData) {
      jumpToReleventDiv(getLocalData)
    }
    localStorage.removeItem('jumptoelement')
    getLoginData()
  }, []);

  const getLoginData = () => {
    const getLocalData = localStorage.getItem('userLoginDetails')
    if (getLocalData !== '' && getLocalData !== null && getLocalData !== undefined) {
      const userDetails = JSON.parse(getLocalData)
      setIsLogin(true)
      setLoginUserData(userDetails)
      console.log('userDetails', userDetails)
    }
  }
  // function to toggle between light and dark theme
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const jumpToReleventDiv = (id = 'home') => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    if (releventDiv) {
      releventDiv.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate('/home')
    }
    setOpen(false)
    // localStorage.removeItem('jumptoelement')
  }


  return (
    <>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <Box component="div" className="solution-banner-area"  onMouseDown={(e) => e.preventDefault()} >
        <Box component="div" className="container-fluid">
          <Box component="div" className="row align-items-center">
            <Box component="div" className="col-lg-6 col-md-12">
              <Box component="div" className="solution-banner-content">
                <Box
                  component="h1"
                  sx={headingColor()}
                >
                  Experience the AI Healthcare Research and Concept Lab
                </Box>
                <Box component="p" sx={{ fontWeight: 700 }}>
                  {/* We strive to shape the future of healthcare through cutting-edge
                    AI technologies. */}
                  Shaping the future of healthcare through cutting-edge AI technologies
                </Box>
                <Box component="ul" className="banner-btn">
                  <Box component="li">
                    {/* <Link underline="none" to="#" className="btn btn-primary">
                      Get Started
                    </Link> */}
                    {/* <BannerForm updateUser={() => {
                      getLoginData()
                    }} buttonName={'Spin Your Engine'} /> */}
                    {/* <BannerContactModal buttonName={'Spin Your Engine'} /> */}
                    <Box>
                      <Button type="submit" variant="contained"
                        // onClick={() => navigate('/login')}
                        onClick={() => jumpToReleventDiv('contact')}
                        sx={{
                          // backgroundColor: 'rgb(117, 120, 255)',
                          ...commonButtonStyle(),
                          ...padding({ r: 4, l: 4, a: 1 })
                        }}
                      >
                        Spin Your Engine
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component="div" className="col-lg-6 col-md-12">
              <Box component="div" className="solution-banner-image" onMouseDown={(e) => e.preventDefault()}>
                <img draggable={false} src="/assets/img/ai-solution/banner/main.png" alt="header" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component="div" className="solution-banner-shape-1">
          <img draggable={false} src="/assets/img/ai-solution/banner/shape1.png" alt="header" />
        </Box>
        <Box component="div" className="solution-banner-shape-2">
          <img draggable={false} src="/assets/img/ai-solution/banner/shape2.png" alt="header" />
        </Box>
      </Box>

    </>

  )
}

export default Home;