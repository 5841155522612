import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import CountUp from "react-countup";
import Iconify from './Iconify';

const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

DashboardCount.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    navigateUrl: PropTypes.string
};

export default function DashboardCount({ title, total, icon, color = '#333333', sx, navigateUrl = '', ...other }) {
    const navigate = useNavigate()
    const [navigatePath, setNavigatePath] = useState(null)
    useEffect(() => {
        setNavigatePath(navigateUrl)
    }, [])
    return (
        <Card
            // onClick={() => {
            //     navigate(navigatePath)
            // }}
            sx={{
                py: 5,
                px: 5,
                backgroundColor: color,
                textAlign: 'center',
                border: '1px solid #ffac00'
            }}
        >
            <Grid Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <StyledIcon
                        sx={{
                            color: 'white',
                            backgroundImage: '#333333',
                        }}
                    >
                        <Iconify icon={icon} width={24} height={24} />
                    </StyledIcon>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Stack direction={'column'}>
                        <Typography variant="subtitle2" sx={{
                            color: 'white',
                            opacity: 0.72
                        }}>
                            {title}
                        </Typography>
                        <Typography variant="h3">
                            <CountUp start={0} end={total} duration={1} separator={""} />
                            {/* {total} */}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

        </Card>
    );
}
