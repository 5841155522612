import { Box, Typography, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getColor, padding } from 'src/palette';




InputError.propTypes = {
    errorDetails: PropTypes.array,
    show: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};
export default function InputError(
    {
        errorDetails=[],
        validate=[],
        onChange,
        value,
        disabled,
        placeholder
    }
) {
    const [isValid, setIsValid] = useState(true)
    const [msg, setMsg] = useState('')
    const [showValidation, setShowValidation] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setMessages()
    }, [errorDetails, validate])


    const setMessages = () => {
        const msg = errorDetails?.length > 0 ? errorDetails[0]?.msg : '';
        const validData = errorDetails[0]?.notValid ?? false;
        setMsg(msg)
        setIsValid(validData)
        setShowValidation(validate)
        setIsDisabled(disabled)

    }
    // const INPUT_BOX_COLOR = {
    //     backgroundColor: '#343a40',
    //     '& .MuiInputBase-input': {
    //         color: getColor('white'),
    //     },
    //     '& .MuiOutlinedInput-input::placeholder': {
    //         color: getColor('white')
    //     },
    //     opacity: isDisabled ? 0.6 : 1,
    //     borderRadius: 3
    // }
    const INPUT_BOX_COLOR = {
        '& .MuiInputBase-input': {
            backgroundColor: '#343a40',
            color: getColor('white'),
            border: `1px solid ${getColor('input_background')}`,
            borderRadius: 8,
            ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: getColor('input_background'),
                borderRadius: 8, // Border radius for the outline
            },
            '&:hover fieldset': {
                borderColor: getColor('input_background'),
            },
            '&.Mui-focused fieldset': {
                borderColor: getColor('input_background'),
            },
            '& .MuiOutlinedInput-input': {
                ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
            },
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('white')
        },
        opacity: isDisabled ? 0.6 : 1,
    };
    
    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <TextField
                error={isValid && showValidation}
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                }}
                fullWidth
                placeholder={placeholder}
                name="Email"
                sx={INPUT_BOX_COLOR}
                InputProps={{
                    readOnly: isDisabled,
                }}
                autoComplete={'off'}
                color='primary'
            />
        </Box>
        <Box sx={{ height: '10px', padding: 1 }}>
            {isValid && showValidation ? <Typography variant="subtitle" sx={{ color: 'red' }}>{msg}</Typography> : <></>}
        </Box>
    </>
    );
}
