import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { getColor, padding } from 'src/palette';
import SendIcon from '@mui/icons-material/Send';




InputError.propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};
export default function InputError(
    {
        onChange,
        onSubmit,
        value,
        disabled,
        placeholder
    }
) {
    const INPUT_BOX_COLOR = {
        '& .MuiInputBase-input': {
            // backgroundColor: '#343a40',
            color: getColor('white'),
            border: `1px solid ${getColor('white')}`,
            borderRadius: 8,
            ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: getColor('white'),
                borderRadius: 8, // Border radius for the outline
            },
            '&:hover fieldset': {
                borderColor: getColor('white'),
            },
            '&.Mui-focused fieldset': {
                borderColor: getColor('white'),
            },
            '& .MuiOutlinedInput-input': {
                ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
            },
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('white')
        },
        opacity: disabled ? 0.6 : 1,
    };

    return (
        <>
            <TextField
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                }}
                fullWidth
                placeholder={placeholder}
                name="Email"
                sx={INPUT_BOX_COLOR}
                autoComplete={'off'}
                color='primary'
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onSubmit();
                    }
                }}
            />
        </>
    );
}
