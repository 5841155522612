import { Box, Card, Grid, Typography, Stack, CardMedia, Drawer, IconButton, AppBar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { appApi } from 'src/apiUtils/AppApi';
import { getBlogDataApi } from 'src/apiUtils/apiPath';
import PropTypes from 'prop-types';
import { config } from 'src/config/config'
import moment from 'moment-timezone';
import { Edit as EditIcon, Add as AddIcon, Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { SearchField, CustomPagination } from 'src/components'
import { getColor } from 'src/palette';

const serverImage = config.serverImage
BlogList.propTypes = {
    openSelectedBlog: PropTypes.func,
    getCount: PropTypes.func
};

export default function BlogList({ openSelectedBlog, getCount }) {
    const [blogData, setBlogData] = useState([]);
    const [selectedBlogData, setSelectedBlogData] = useState([]);
    const [selectedBlogId, setSelectedBlogid] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [Disable, setDisable] = useState(false)
    const useRefValue = useRef(true)
    const [blogDataCount, setBlogDataCount] = useState(0)
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        getBlogDetails()
    }, [page, searchValue]);

    const getBlogDetails = () => {
        setLoading(true);
        const getLocalData = localStorage.getItem('userLoginDetails') ? JSON.parse(localStorage.getItem('userLoginDetails')) : {};

        const param = {
            user_id: `${getLocalData ? getLocalData.id : ''}`,
            page,
            limit: 6,
            search: searchValue
        };
        getBlogDataApi(param).then((result) => {
            if (result.status) {
                const responseData = result.entity;
                if (responseData?.blogList) {
                    setBlogData(responseData?.blogList.map((data) => {
                        data.content = data.original_blog;
                        return data;
                    }));
                    if (responseData?.blogList.length > 0 && (selectedBlogId === '' || selectedBlogId === null || selectedBlogId === undefined)) {
                        let firstData = responseData?.blogList[0]
                        firstData.content = firstData.original_blog;
                        setSelectedBlogData(firstData)
                        setSelectedBlogid(firstData.id);
                        openSelectedBlog(firstData);
                        setDisable((responseData?.blogList?.length ? responseData?.blogList?.length : 0) ? false : true)
                        getCount(responseData?.blogList ? responseData?.blogList?.length : 0)
                    }
                    setBlogDataCount(responseData.blogCount)
                }

            }
        })
            .catch(error => {
                // Handle error
                console.error(error);
            })
            .finally(() => setLoading(false));
    };
    return (
        <>
            <IconButton onClick={() => {
                setOpen(true)
                getBlogDetails()
            }}
                disabled={Disable}
                sx={{ opacity: Disable ? .5 : 1 }}
            >
                <MenuIcon sx={{ color: getColor('white') }} />
            </IconButton>
            <Drawer anchor={"right"} open={open} sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { xs: 290, sm: 290, md: 600, lg: 600, xl: 600 },
                    boxSizing: 'border-box',
                },
                position: 'relative',
            }}>
                <Box sx={{ padding: 2, backgroundColor: '#343a40', width: '100%' }}>
                    <Stack direction={'row'} sx={{ padding: 2, backgroundColor: '#343a40', width: '100%' }}>
                        <SearchField
                            onChange={(event) => {
                                console.log('test search', event)
                                setPage(1)
                                setSearchValue(event)
                            }}
                        />
                        <IconButton onClick={() => {
                            setOpen(false)
                        }}>
                            <CloseIcon sx={{ color: getColor('white'), fontSize: '180%', cursor: 'pointer' }} />
                        </IconButton>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CustomPagination onPageChange={(value) => setPage(value)} count={blogDataCount} rowPerPage={6} />
                    </Box>
                </Box>
                <Box id="drawer-content" sx={{ backgroundColor: '#343a40', overflowY: 'auto', height: '100%' }}>
                    {blogData && blogData.length > 0 ? <Grid container spacing={2} sx={{ padding: 1 }}>
                        {blogData.map((blog) => (
                            < Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={{
                                    padding: 1,
                                    margin: 1,
                                    borderRadius: '10px',
                                    "&:hover": {
                                        cursor: 'pointer',
                                    },
                                    backgroundColor: '#222222',
                                    width: '100%',
                                    height: '100%',
                                    border: `${parseInt(selectedBlogId) === parseInt(blog.id) ? '1px solid #ffac00' : ''}`
                                }}
                                    onClick={() => {
                                        setSelectedBlogData(blog)
                                        setSelectedBlogid(blog.id)
                                        openSelectedBlog(blog)
                                    }}
                                    key={blog.id}
                                >
                                    <Box sx={{
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        "&:hover": {
                                            opacity: 0.7,
                                        }
                                    }}>
                                        <CardMedia
                                            component="img"
                                            image={`${blog.blog_picture ? serverImage + blog.blog_picture : '/assets/img/blog/emptyblog.png'}`}
                                            alt="File Preview"
                                            height={'150px'}
                                            width={'100px'}
                                        />

                                    </Box>

                                    <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                                        <Typography variant='subtitle1'>
                                            {moment(blog.createdAt).format('DD MMM, YYYY')}
                                        </Typography>
                                    </Box>
                                    <Typography align={'justify'} sx={{
                                        "&:hover": {
                                            cursor: 'pointer',
                                            // color: '#0ca59d'
                                        }
                                    }} variant='h6'>{blog.blog_title}</Typography>
                                </Box>
                            </Grid>
                        ))}

                    </Grid>
                        : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography variant="h6" sx={{ color: 'red' }}>No Blog Found</Typography>
                        </Box >}

                    {/* </Stack> */}
                </Box >
            </Drawer >

        </>
    )
}