
import { Box, Link } from '@mui/material';
import EmailForm from '../EmailForm/EmailForm';
import PropTypes from 'prop-types';
import { headingColor } from 'src/palette';

ContactSection.propTypes = {
  userData: PropTypes.object,
  updateUser: PropTypes.func
};


function ContactSection({ userData, updateUser }) {


  return (
    <section id="contact" className="contact-area ptb-110">
      <Box component="div" className="container">
        <Box component="div" className="section-title" onMouseDown={(e) => e.preventDefault()} >
          <Box component="h2" sx={headingColor()}>Contact Us</Box>
          <Box component="h2">Drop us Message for any Query</Box>
        </Box>
        <Box component="div" className="row align-items-center">
          <Box component="div" className="col-lg-4 col-md-4">
            <Box component="div" className="contact-image" onMouseDown={(e) => e.preventDefault()}>
              <img draggable={false} src="/assets/img/contact.png" alt="contact" />
            </Box>
          </Box>
          <Box component="div" className="col-lg-8 col-md-8">
            <EmailForm userData={userData} updateUser={() => {
              updateUser()
            }} />
          </Box>
        </Box>
        <Box component="div" className="row" onMouseDown={(e) => e.preventDefault()} >
          <Box component="div" className="col-lg-4 col-md-6 col-sm-6">
            <Box component="div" className="contact-info-box">
              <Box component="div" className="icon">
                <i className="bx bx-envelope" />
              </Box>
              <Box component="h3">Email Here</Box>
              <Box component="p">
                <Link underline="none" href="mail:hello@inolabs.com">
                  <Box component="span"
                    className="__cf_email__"
                    data-cfemail="b9d8ddd4d0d7f9dccfd6d5cdd897dad6d4"
                  >
                    hello@inolabs.ai
                  </Box>
                </Link>
              </Box>
              <Box component="p">
                <Link underline="none">
                  <Box component="span"
                    className="__cf_email__"
                    data-cfemail="5d34333b321d382b3231293c733e3230"
                  >
                    {/* [email&nbsp;protected] */}
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-md-6 col-sm-6">
            <Box component="div" className="contact-info-box">
              <Box component="div" className="icon">
                <i className="bx bx-map" />
              </Box>
              <Box component="h3">Location Here</Box>
              <Box component="p">
                Bangalore, India
              </Box>
              <Box component="p">
                & &nbsp;United States
                {/* New York, <Box component="b" /> Canada */}
              </Box>
            </Box>
          </Box>
          <Box component="div" className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <Box component="div" className="contact-info-box">
              <Box component="div" className="icon">
                <i className="bx bx-phone-call" />
              </Box>
              <Box component="h3">Call Here</Box>
              <Box component="p">
                <Link underline="none" href="tel:+919008522866">+91 90085 22866</Link>
                <Box component={'span'}>&nbsp;,&nbsp;</Box>
                <Link underline="none" href="tel:+919741790001">+91 97417 90001</Link>
              </Box>
              <Box component="p">
              <Link underline="none" href="tel:+12056369696"> +1 205-636-9696</Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>

  )
}

export default ContactSection;