import { Box, Typography, TextField, Chip, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getColor, padding, margin } from 'src/palette';




InputError.propTypes = {
    errorDetails: PropTypes.array,
    show: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    menuList: PropTypes.array,
    id: PropTypes.string,
    label: PropTypes.string
};
export default function InputError(
    {
        errorDetails = [],
        validate = [],
        onChange,
        value,
        disabled,
        placeholder,
        menuList,
        id,
        label
    }
) {
    const [isValid, setIsValid] = useState(true)
    const [msg, setMsg] = useState('')
    const [showValidation, setShowValidation] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setMessages()

    }, [errorDetails, validate])


    const setMessages = () => {
        const msg = errorDetails?.length > 0 ? errorDetails[0]?.msg : '';
        const validData = errorDetails[0]?.notValid ?? false;
        setMsg(msg)
        setIsValid(validData)
        setShowValidation(validate)
        setIsDisabled(disabled)

    }
    const INPUT_BOX_COLOR = {
        '& .MuiInputBase-input': {
            // backgroundColor: '#343a40',
            color: getColor('white'),
            border: `1px solid ${getColor('white')}`,
            borderRadius: 8,
            ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: getColor('white'),
                borderRadius: 8, // Border radius for the outline
            },
            '&:hover fieldset': {
                borderColor: getColor('white'),
            },
            '&.Mui-focused fieldset': {
                borderColor: getColor('white'),
            },
            '& .MuiOutlinedInput-input': {
                ...padding({ l: 4, r: 4, a: 2 }), // Adjust padding as needed
            },
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: getColor('white')
        },
        opacity: isDisabled ? 0.6 : 1,
    };
    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <TextField
                error={isValid && showValidation}
                fullWidth
                select
                name="Email"
                sx={INPUT_BOX_COLOR}
                SelectProps={{
                    placeholder: placeholder,
                    value: value,
                    onChange: (event) => {
                        onChange(event.target.value)
                    },
                }}
                autoComplete={'off'}
                color='primary'
            >
                {menuList?.length > 0 ? menuList.map((e) => (
                    <MenuItem key={(e?.id || e?.label ? e[id || label] : e)} value={(e?.id || e?.label ? e[id || label] : e)}>{(e?.label ? e[label] : e)}</MenuItem>
                )) : <><MenuItem key={''} value={''}>{'None'}</MenuItem></>
                }

            </TextField>
        </Box>
        <Box sx={{ height: '10px', padding: 1 }}>
            {isValid && showValidation ? <Typography variant="subtitle" sx={{ color: 'red' }}>{msg}</Typography> : <></>}
        </Box>
    </>
    );
}
