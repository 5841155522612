import { Navigate, useRoutes } from 'react-router-dom';
import Home from './Pages/HomePages/Home'
import AdminLayout from './Layout/AdminLayout'
import { Dashboard, Blogs, BasicComponent, RequestList } from './Pages/AdminPage';
import Blog from './Pages/HomePages/Blog';
import BlogDetails from './Pages/HomePages/BlogDetails';
import ComingSoon from './Pages/ComingSoon';
import { AuthuProtectedRoute, ChatProtectedRoute } from './protectedRoute';
import { Login } from './Pages/Login';
import { ChatLayout, ChatForm, DietPlanForm } from './Pages/ChatBox'


// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="home" />, index: true }
      ]
    },

    {
      path: '/admin',
      element: <AuthuProtectedRoute><AdminLayout /></AuthuProtectedRoute>,
      // element: <MainLanding />,
      children: [
        { element: <Navigate to="/admin/home" />, index: true },
        { path: 'home', element: <Dashboard /> },
        { path: 'blog', element: <Blogs /> },
        { path: 'basic', element: <BasicComponent /> },
        { path: 'request-details', element: <RequestList /> }

      ],
    },
    {
      path: '/home',
      element: <Home />,
    },
    // {
    //   path: '/blog',
    //   element: <Blog />,
    // },
    // {
    //   path: '/login',
    //   element: <Login />,
    // },
    {
      path: '/coming-soon',
      element: <ChatLayout />,
    },
    // {
    //   path: '/chat',
    //   element: <ChatProtectedRoute><ChatLayout /></ChatProtectedRoute>,
    //   children: [
    //     { element: <Navigate to="/chat/home" />, index: true },
    //     { path: 'home', element: <ChatForm /> },
    //     { path: 'diet-plan', element: <DietPlanForm /> }
    //   ]
    // },
    {
      path: '/chat',
      element: <ChatProtectedRoute><ChatLayout /></ChatProtectedRoute>,
    },
    {
      path: '/diet-plan',
      element: <ChatProtectedRoute><DietPlanForm /></ChatProtectedRoute>,
    },
    // {
    //   path: '/blog-details',
    //   element: <BlogDetails />,
    // },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  return routes;
}
